import React from "react";
import { Table, Button } from "react-bootstrap";

function DocumentList({ documents, handleShowUpdate }) {

  return (
    <Table bordered>
      <thead>
        <tr>
          <th>File Name</th>
          <th>Type</th>
          <th>Revision Date</th>
          <th>Uploaded On</th>
        </tr>
      </thead>
      <tbody>
        {documents.map((doc, index) => (
          <tr key={index}>
            <td><a href={"https://backend.lumyn.ai/nhpcfiles/"+doc.filename} target="_blank" className="link">{doc.filename}</a></td>
            <td>{doc.type} <br/><a href={"https://backend.lumyn.ai/nhpcfiles/"+doc.parentfilename} target="_blank" className="link">{doc.parentfilename}</a></td>
            <td>{doc.revisionDate || "N/A"}</td>
            <td>{doc.uploadedOn}</td>            
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default DocumentList;
