import React, { useState } from 'react';
import Modal from 'react-modal';

// Custom styling for the modal in dark theme
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#333',
    color: '#fff',
    border: '1px solid #444',
    borderRadius: '10px',
    width: '80%',
    height: '90%',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

// Bind modal to your app element (for accessibility)
Modal.setAppElement('#root');

const FileModal = ({ filename, filepath , pageNumber}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <a
        href="#!"
        onClick={openModal}
        title={filename}
        className="multidoclink"
      >
        <i className="fa fa-file fileicon"></i> {truncateItem(filename)}<br/>
        <span className="pagenumber">{pageNumber !== 0 ? `Page No ${pageNumber}` : ''}</span>
      </a>

      
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="File Modal"
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom:'10px' }}>
          <h4>{filename}</h4>
          <button
            onClick={closeModal}
            style={{
              backgroundColor: 'transparent',
              color: '#fff',
              border: 'none',
              cursor: 'pointer',
              fontSize: '20px',
            }}
          >
            <i className="fa fa-close"></i>
          </button>
        </div>
        <div>
          {/* if pageNumber is 0 */}
          {pageNumber === 0 && (  
            <iframe
              src={filepath}
              width="100%"
              height="600px"
              title={filename}
            ></iframe>
          )}
            
          {/* if pageNumber is not 0 */}
          {pageNumber !== 0 && (
            <iframe
              src={`${filepath}#page=${pageNumber}`}
              width="100%"
              height="600px"
              title={filename}
            ></iframe>
          )}
        </div>
      </Modal>
    </div>
  );
};

// Function to truncate the item text if it exceeds 25 characters
const truncateItem = (text) => {
  return text.length > 30 ? text.substring(0, 30) + '...' : text;
};

export default FileModal;
