import React, { useState, useRef } from "react";
import { Form, Button, Card } from "react-bootstrap";
import SideBarMobile from "../components/side-bar";
import { NavLink, Link } from "react-router-dom";
import HeaderNavbar from "../components/headernavbar";
import AIService from "../services/FinanceAIService";

const FinanceExtractInvoice = () => {
  const [isDragging, setIsDragging] = useState(false);
  //defining the state variables
  const [file, setFile] = useState("");
  const [key, setKey] = useState("123456789");
  const [type, setType] = useState("0");
  const [responsedata, setResponseData] = useState("");
  const [progress, setProgress] = useState(0);
  const [isFilePicked, setIsFilePicked] = useState(false);

  //method to handle the form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    //creating the form data
    const formData = new FormData();
    formData.append("file", file);
    formData.append("key", key);
    formData.append("type", type);

    console.log(file);

    //Change button text to "Loading..." while waiting for response
    const submitButton = document.querySelector('button[type="submit"]');
    submitButton.disabled = true;
    submitButton.innerText = "Processing...";

    //calling the extract method from AIService
    const response = await AIService.processInvoice(formData);
    console.log(response);

    //Change button text back to "Proceed" after response
    submitButton.disabled = false;
    submitButton.innerText = "Proceed";

    //setting the data in the state
    setResponseData(response);
  };

  //Download the response data as a JSON file
  const downloadJSON = () => {
    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(responsedata)], {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = "response.json";
    document.body.appendChild(element);
    element.click();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    //const files = e.dataTransfer.files;
    // Handle the dropped files (e.g., upload or process them)
    const file = e.dataTransfer.files[0];
    setFile(file);
    console.log("Dropped files:", e.dataTransfer.files[0]);
    if (file) {
      // Start the file upload process
      uploadFile(file);
    }
  };

  //method to handle the file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    console.log("selected files:", e.target.files[0]);
    if (file) {
      // Start the file upload process
      uploadFile(file);
    }
  };

  const uploadFile = (file) => {
    const totalPercentage = 100;
    let startPercent = 0;

    const interval = setInterval(() => {
      // Simulate progress
      startPercent++;
      const newProgress = (startPercent / totalPercentage) * 100;
      setProgress(newProgress);

      if (startPercent === totalPercentage) {
        // File upload is complete
        clearInterval(interval);
        setIsFilePicked(true);
      }
    }, 30);
  };

  return (
    <div>
      <main>
        <section>
          <div className="container-fluid p-0">
            <div className="d-flex mobile-nonedflex">
              <div className="">
                <div className="mobile-hidden">
                  <nav className="left__sidebar">
                    <div className="brand__image">
                      <img
                        src="/images/logo-brand.png"
                        alt="Band Logo"
                        className="brand__logo"
                      />
                    </div>
                    <div className="left-side-item">
                      <ul className="nav__bar__link">
                        <li>
                          <a

                            className="active_link"
                          >
                            Process Invoice
                          </a>
                        </li>
                        {/* <li>
                        <Link to="/finance-process-ewaybill">
                          Process E-Way Bill
                        </Link>
                      </li>
                      <li>
                        <Link to="/finance-process-weighbridgeslip">
                          Weighbridge Slip
                        </Link>
                      </li> */}
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>

              <div
                className=""
                style={{
                  width: "100%",
                }}
              >
                <div className="right__sidebar">
                  <HeaderNavbar />
                  <div className="fix-section">
                    <div className="row gx-4">
                      <div className="col-md-5 source-file-bgonly px-4">
                        <Form onSubmit={handleSubmit}>
                          <h5 className="text-headingenter my-4">
                            Upload File to process Invoice
                          </h5>
                          <div
                            className={`file-upload ${isDragging ? "dragging" : ""
                              }`}
                            onDragEnter={handleDragEnter}
                            onDragOver={(e) => e.preventDefault()}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                          >
                            <p className="position-relative">
                              Drag and Drop files or folders, or
                              <input
                                type="file"
                                placeholder="Browse"
                                className="file-input"
                                onChange={handleFileChange}
                              />
                              <span className="or-browse">Browse</span>
                            </p>
                            <span className="sublinetag">
                              maximum file size 20 mb
                            </span>
                          </div>

                          <div
                            className="alert alert-progressbar m-3"
                            style={{ display: file ? "block" : "none" }}
                          >
                            <div className="d-flex align-items-center gx-4">
                              <div className="progress-barimages">
                                <img
                                  src="images/file-image.svg"
                                  alt=""
                                  className=""
                                />
                              </div>
                              <div className="progressbar-word">
                                <div className="task-progress">
                                  <p>{`Uploading file ${progress.toFixed(
                                    0
                                  )}%`}</p>
                                  <progress
                                    className="progress progress2"
                                    max="100"
                                    value={progress}
                                  ></progress>
                                </div>
                              </div>
                            </div>
                          </div>

                          {isFilePicked ? (
                            <div
                              className="mt-2 pt-lg-5"
                              style={{ textAlign: "right" }}
                            >
                              <Button className="btn process-btn" type="submit">
                                Proceed
                              </Button>
                            </div>
                          ) : null}
                        </Form>
                      </div>

                      {/* Check if response data is not empty and reponsedata.status is success*/}
                      {responsedata ? (
                        <div className="col-md-7 px-3">
                          <div className="">
                            <div className="sourcecard-body">
                              <div className="hedingcopyoption__flexd">
                                <div className="icon__copyedit">
                                  <Link href="">
                                    <img
                                      src="images/copy-img.png"
                                      alt=""
                                      className="copy__icon"
                                    />
                                  </Link>
                                  <Link href="">
                                    <img
                                      src="images/Download.png"
                                      alt=""
                                      className="edit__icon"
                                      onClick={downloadJSON}
                                    />
                                  </Link>
                                </div>
                              </div>

                              <h3>Invoice</h3>

                              <div class="invoice-details mt-4">
                                {/* Check if billto is not null*/}
                                {responsedata.data.bill_to ? (
                                  <div>
                                    <p>
                                      <strong>Bill To:</strong>{" "}
                                      {responsedata.data.bill_to.company_name}
                                    </p>
                                    <p>
                                      <strong>Address:</strong>{" "}
                                      {responsedata.data.bill_to.address_line1},{" "}
                                      {responsedata.data.bill_to.city},{" "}
                                      {responsedata.data.bill_to.state} -{" "}
                                      {responsedata.data.bill_to.pincode}
                                    </p>
                                    <p>
                                      <strong>GSTIN:</strong>{" "}
                                      {responsedata.data.bill_to.gstin}
                                    </p>
                                  </div>
                                ) : (
                                  <div></div>
                                )}

                                {/* Check if invoicedby is not null*/}
                                {responsedata.data.invoiced_by ? (
                                  <div>
                                    <p>
                                      <strong>Invoiced By:</strong>{" "}
                                      {
                                        responsedata.data.invoiced_by
                                          .company_name
                                      }
                                    </p>
                                    <p>
                                      <strong>Address:</strong>{" "}
                                      {
                                        responsedata.data.invoiced_by
                                          .address_line1
                                      }
                                      , {responsedata.data.invoiced_by.city},{" "}
                                      {responsedata.data.invoiced_by.state} -{" "}
                                      {responsedata.data.invoiced_by.pincode}
                                    </p>
                                    <p>
                                      <strong>GSTIN:</strong>{" "}
                                      {responsedata.data.invoiced_by.gstin}
                                    </p>
                                  </div>
                                ) : (
                                  <div></div>
                                )}

                                <br />
                                <p>
                                  <strong>Invoice Number:</strong>{" "}
                                  {responsedata.data.invoice_no}
                                </p>
                                <p>
                                  <strong>Invoice Date:</strong>{" "}
                                  {responsedata.data.invoice_date}
                                </p>
                                <p>
                                  <strong>Invoice Amount:</strong>{" "}
                                  {responsedata.data.invoice_amount}
                                </p>
                              </div>
                              <table class="invoice-table">
                                <thead>
                                  <tr>
                                    <th>Item</th>
                                    <th>HSN/SAC</th>
                                    <th>Quantity</th>
                                    <th>Rate</th>
                                    <th>SubTotal</th>
                                    <th>CGST</th>
                                    <th>SGST</th>
                                    <th>IGST</th>
                                    <th>GST Amount</th>
                                    <th>Total</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {responsedata.data.items.map((item) => (
                                    <tr>
                                      <td>
                                        {item.item}
                                        <br />
                                        <small>{item.description}</small>
                                      </td>
                                      <td>{item.hsn_sac_code}</td>
                                      <td>
                                        {item.qty} {item.uom}
                                      </td>
                                      <td>{item.rate}</td>
                                      <td>{item.subtotal}</td>
                                      <td>{item.cgst_rate}</td>
                                      <td>{item.sgst_rate}</td>
                                      <td>{item.igst_rate}</td>
                                      <td>{item.gst_amount}</td>
                                      <td>{item.total}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <div class="invoice-total">
                                <p>
                                  <strong>Sub Total:</strong>{" "}
                                  {responsedata.data.subtotal_amount}
                                </p>
                                <p>
                                  <strong>GST Total:</strong>{" "}
                                  {responsedata.data.gsttotal_amount}
                                </p>
                                <p>
                                  <strong>Payable Amount:</strong>{" "}
                                  {responsedata.data.payable_amount}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <p> No data found </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default FinanceExtractInvoice;
