import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom'

const NhpcHeadernavbar = () => {
    const location = useLocation();
    const [section, setSection] = useState('');

    useEffect(() => {
        const path = location.pathname;
        const page = path.split('/')[1];
        const section = path.split('/')[1];

        console.log("Section", section);
        if (section == null || section == "" || section == undefined) {
            setSection("knowledge");
        } else {
            setSection(section);
        }
    }, [location]);

    const handleLogout = () => {
        localStorage.clear();
        window.location.href = "/";
    };


    return (
        <div>
            <header className="desktopdisplayblock nhpc">
                <nav className="d-flex justify-content-between">
                    <div className="brand__image">
                        <img
                            src="/images/logo-brand.png"
                            alt="Band Logo"
                            className="brand__logo"
                        />
                    </div>
                    <ul className="rigth__sidebarul nhpc">
                        <li>
                            <Link to="/nhpc-knowledge-search" className={section === 'nhpc-knowledge-search' ? 'active__headeraher' : ''}>
                                <img src="images/icon-knowledge.svg" className="top__barimg" /> Knowledge Search
                            </Link>
                        </li>
                        <li>
                            <Link to="/nhpc-settings" className={section === 'nhpc-settings' ? 'active__headeraher' : ''}>
                                <img src="images/icon-settings.svg" className="top__barimg" /> Settings
                            </Link>
                        </li>
                    </ul>
                    <div>
                        <ul className="ul-dleftype">

                            <li>
                                <div className="user-icon-dropdown">
                                    <img src="images/search-home/user-icon.png" className="avterimg" />
                                </div>
                            </li>
                            <li>
                                <div className="text-white user-icon-dropdown">
                                    Hi Admin
                                    <div className="dropdown-content">
                                        <button className="dropdown-item" onClick={handleLogout}>
                                            Logout
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        </div>
    )
}

export default NhpcHeadernavbar
