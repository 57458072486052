import React, { useState } from 'react';
import SideBarMobile from '../components/side-bar';
import { NavLink, Link, useLocation } from 'react-router-dom';
import SideBarMobileEmailTool from '../components/side-bar-emailtool';
import HeaderNavbar from '../components/headernavbar';

const PersonalWriteEmail = () => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [status, setStatus] = useState('newmailtab')
    const handleInputClick = () => {
        setShowTooltip(true);
    };


    return (
        <div>
            <main>
                <section>
                    <div className="container-fluid p-0">
                        <div className="d-flex mobile-nonedflex">
                            <div className=''>

                                <div className="mobile-hidden">
                                    <nav className="left__sidebar">
                                        <div className="brand__image">
                                            <img src="/images/logo-brand.png" alt="Band Logo" className="brand__logo" />
                                        </div>
                                        <ul className="nav__bar__link">
                                            <li>
                                                <Link to="/personal-write-email" className="active_link">Write Email</Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>

                            <div className='temeory-width'>
                                <div className='right__sidebar'>
                                    <HeaderNavbar />

                                    <div className='bg-changecontent'>
                                        <div className='chat__section'>
                                            <div className='chat__boxquestion'>
                                                <ul className='newmailul'>
                                                    <li><Link to="" onClick={(e) => setStatus("newmailtab")}
                                                        className={`newmail ${status === "newmailtab" ? 'neactive' : ''}`}>New mail</Link></li>
                                                    <li><Link to="" onClick={(e) => setStatus("writereply")}
                                                        className={`newmail ${status === "writereply" ? 'neactive' : ''}`}>Write reply</Link></li>
                                                </ul>
                                            </div>

                                            {
                                                status === 'newmailtab' &&
                                                <>

                                                    <div className='width__controlnew68'>
                                                        <div className='context-area'>
                                                            <textarea className='context__textarea' placeholder='Context'></textarea>
                                                        </div>
                                                    </div>
                                                    <div className='width-control'>
                                                        <div className='list-dflex'>
                                                            <div className='tone'>
                                                                <p className='tone__text'>Tone</p>
                                                                <ul>
                                                                    <li className='sub-button'>Neutral</li>
                                                                    <li className='sub-button'>Formal</li>
                                                                    <li className='sub-button'>Informal</li>
                                                                </ul>
                                                            </div>
                                                            <div className='tone length'>
                                                                <p className='tone__text'>Length</p>
                                                                <ul>
                                                                    <li className='sub-button'>Short</li>
                                                                    <li className='sub-button'>Long  </li>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className='button-genration'>
                                                            <button className='genration-btn'>Generate</button>
                                                        </div>
                                                    </div>
                                                </>
                                            }


                                            {
                                                status === 'writereply' &&
                                                <>
                                                    <div className='width__controlnew68'>
                                                        <div className='context-area'>
                                                            <textarea className='context__textarea' placeholder='Paste your mail here'></textarea>
                                                            <div className='copy-image'></div>
                                                        </div>
                                                    </div>
                                                    <div className='width-control pt-3 width__controlnew68'>
                                                        <input type='text' name='' id='' className='tittle__input' placeholder='What do you want to say?' />
                                                    </div>

                                                    <div className='width-control'>
                                                        <div className='list-dflex'>
                                                            <div className='tone'>
                                                                <p className='tone__text'>Tone</p>
                                                                <ul>
                                                                    <li className='sub-button'>Neutral</li>
                                                                    <li className='sub-button'>Formal</li>
                                                                    <li className='sub-button'>Informal</li>
                                                                </ul>
                                                            </div>
                                                            <div className='tone length'>
                                                                <p className='tone__text'>Length</p>
                                                                <ul>
                                                                    <li className='sub-button'>Short</li>
                                                                    <li className='sub-button'>Long  </li>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className='button-genration'>
                                                            <button className='genration-btn'>Generate</button>
                                                        </div>
                                                    </div>                                                    
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default PersonalWriteEmail
