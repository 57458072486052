import React, { useState } from 'react';
import SideBarMobile from '../components/side-bar';
import { NavLink, Link, useLocation } from 'react-router-dom';
import SideBarMobileEmailTool from '../components/side-bar-emailtool';
import HeaderNavbar from '../components/headernavbar';

const MarketingProductDescription = () => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [status, setStatus] = useState('newmailtab')
    const handleInputClick = () => {
        setShowTooltip(true);
    };


    return (
        <div>
            <main>
                <section>
                    <div className="container-fluid p-0">
                        <div className="d-flex mobile-nonedflex">
                            <div className=''>

                                <div className="mobile-hidden">
                                    <nav className="left__sidebar">
                                        <div className="brand__image">
                                            <img src="/images/logo-brand.png" alt="Band Logo" className="brand__logo" />
                                        </div>
                                        <ul className="nav__bar__link">
                                            <li>
                                                <Link to="/marketing-socialmedia-post">Social Media Post</Link>
                                            </li>
                                            <li>
                                                <Link to="/marketing-blog">Blog / Article</Link>
                                            </li>
                                            <li>
                                                <Link to="/marketing-product-description" className="active_link">Product Description</Link>
                                            </li>
                                            <li>
                                                <Link to="/marketing-presentation">Presentation</Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>

                            <div className='temeory-width'>
                                <div className='right__sidebar'>

                                    <HeaderNavbar />


                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="bg-color-change">
                                                <div className='chat__section'>
                                                    <div className='width__control65'>
                                                        <h5 className="text-headingenter">
                                                            Enter Details</h5>

                                                        <div className='context-area mt-5'>
                                                            <input type="text" name="" id="" class="tittle__input" placeholder="Title" />


                                                            <textarea
                                                                className='context__textarea mt-4'
                                                                placeholder='Details'></textarea>

                                                            <div class="tone mt-4">
                                                                <p class="tone__text">Tone</p>
                                                                <ul>
                                                                    <li class="sub-button">Neutral</li>
                                                                    <li class="sub-button">Formal</li>
                                                                    <li class="sub-button">Informal</li>
                                                                </ul>
                                                            </div>

                                                            <div className='button-genration'>
                                                                <button className='genration-btn'>Generate</button>
                                                            </div>
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default MarketingProductDescription
