import React, { useState, useEffect } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import HeaderNavbar from "./NhpcHeadernavbar";
import CategoryList from "./CategoryList";
import SubcategoryList from "./SubcategoryList";
import DocumentList from "./DocumentList";
import CategoryModal from "./CategoryModal";
import SubcategoryModal from "./SubcategoryModal";
import DocumentModal from "./DocumentModal";
import KnowLedgeAIService from "../services/service"; // API service

import "./style.css";
import "react-toastify/dist/ReactToastify.css";

function Settings() {
  const [loading, setLoading] = useState(false); // Loading state
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [showModal, setShowModal] = useState({});
  const [modalData, setModalData] = useState({});

  const fetchCategories = async () => {
    try {
      const response = await KnowLedgeAIService.List_NHPCCategory();
      if (response.data) {
        // Use Object.entries to get both the id (key) and category data (value)
        const categoryArray = Object.entries(response.data).map(([id, item]) => ({
          id: id, // Set the id from the key
          name: item.category, // Set the name from the category field
          subcategories: []
        }));

        setCategories(categoryArray);
        if (categoryArray.length > 0) setSelectedCategory(categoryArray[0].name);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      toast.error("Failed to load categories");
    }
  };

  const fetchSubcategories = async () => {
    if (selectedCategory) {
      try {
        const response = await KnowLedgeAIService.List_NHPCSubCategory(selectedCategory);

        if (response.data) {
          // Use Object.entries to get both the id (key) and category data (value)
          const subcategoryArray = Object.entries(response.data).map(([id, item]) => ({
            id: id, // Set the id from the key
            name: item.subcategory, // Set the name from the category field
            category: item.category
          }));

          setSubcategories(subcategoryArray);          

          // Automatically select the first subcategory if available
          if (subcategoryArray.length > 0) {
            setSelectedSubcategory(subcategoryArray[0].name);
          }
        }
      } catch (error) {
        console.error("Error fetching subcategories:", error);
        toast.error("Failed to load subcategories");
      }
    }
  };

  const fetchDocuments = async () => {
    if (selectedCategory && selectedSubcategory) {
      try {
        // Call the API to get documents filtered by category and subcategory
        const response = await KnowLedgeAIService.List_NHPCDocuments(selectedCategory, selectedSubcategory);

        // Log the entire response to check its structure
        //console.log("API response:", response);

        // Check if response exists and is not empty
        if (response && Object.keys(response).length > 0) {
          //console.log("Response data exists. Processing documents...");

          // Convert response object to an array and map the fields as needed
          // Use Object.entries to get both the id (key) and category data (value)
          const documentArray = Object.entries(response).map(([id, doc]) => ({
            id: id, // Set the id from the key
            filename: doc.filename,
            type: doc.isOriginal === "true" ? "Original" : doc.isRevision === "true" ? "Revision" : doc.isAmendment === "true" ? "Amendment" : "Unknown",
            parentfilename: doc.isOriginal === "true" ? "": doc.parentName,
            revisionDate: doc.revisionDate || "N/A", // Default to "N/A" if revisionDate is empty
            uploadedOn: doc.uploadedOn || "N/A" // Default to "N/A" if uploadedOn is empty
          }));

          // Log the processed document array
          //console.log("Processed Document Array:", documentArray);

          // Set the documents state with the processed array
          setDocuments(documentArray);
        } else {
          console.log("No documents found for the selected category and subcategory.");
          setDocuments([]); // Set documents to an empty array if no data found
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
        toast.error("Failed to load documents");
      }
    } else {
      // Clear documents if either category or subcategory is deselected
      setDocuments([]);
    }
  };

  useEffect(() => {    
    setLoading(true);
    fetchCategories();
    setLoading(false);
  }, []);

  // Fetch subcategories when a category is selected, and automatically select the first subcategory
  useEffect(() => {    
    setLoading(true);
    fetchSubcategories();
    setLoading(false);
  }, [selectedCategory]);

  // Fetch documents when both category and subcategory are selected
  useEffect(() => {  
    setLoading(true);   
    fetchDocuments();
    setLoading(false);
  }, [selectedCategory, selectedSubcategory]); 
  

  const handleShowModal = (modalName, data = {}) => {
    setModalData(data);
    setShowModal({ ...showModal, [modalName]: true });
  };

  const handleCloseModal = (modalName) => {
    setShowModal({ ...showModal, [modalName]: false });
    setModalData({});
  };

  //Category Functions
  const handleUpdateCategory = (categoryId, categoryName) => {
    handleShowModal("updateCategory", { categoryId, categoryName });
  };

  const handleDeleteCategory = async (categoryId,categoryName) => {
    if (window.confirm(`Are you sure you want to delete the category: ${categoryName}?`)) {
      // Call API to delete the category by ID
      await KnowLedgeAIService.Delete_NHPCCategory(categoryId);
      fetchCategories();

      if (selectedCategory === categoryName) {
        setSelectedCategory(null); // Clear selection if the deleted category was selected
        setSelectedSubcategory(null); // Clear subcategory selection
      }
      toast.success("Category deleted successfully");
    }
  };
  
  //SubCategory Functions
  const handleUpdateSubCategory = (subcategoryId, subcategoryName, selectedCategory) => {
    handleShowModal("updateSubcategory", { subcategoryId, subcategoryName, selectedCategory });
  };

  const handleDeleteSubCategory = async (subcategoryId,subcategoryName) => {
    if (window.confirm(`Are you sure you want to delete the subcategory: ${subcategoryName}?`)) {
      // Call API to delete the category by ID
      await KnowLedgeAIService.Delete_NHPCSubCategory(subcategoryId);
      fetchSubcategories();

      if (selectedSubcategory === subcategoryName) {
        setSelectedSubcategory(null); // Clear subcategory selection
      }
      toast.success("Subcategory deleted successfully");
    }
  };

  return (
    <>
      {loading && (
        <div className="loading-indicator">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}

      <HeaderNavbar />
      <ToastContainer />
      <div className="container-fluid">
        <h3 className="py-2 mt-2">Document Management</h3>
        <Row>
          {/* Category Column */}
          <Col md={3} sm={4} xs={12} className="column">
            <h3 className="title-container">
              Categories
              <Button variant="primary" size="sm" onClick={() => handleShowModal("addCategory")}>+ Add</Button>
            </h3>
            <CategoryList
              categories={categories}
              setSelectedCategory={setSelectedCategory}
              setSelectedSubcategory={setSelectedSubcategory}
              selectedCategory={selectedCategory}
              handleShowUpdate={(categoryId, categoryName) => handleUpdateCategory(categoryId, categoryName)}
              handleDeleteCategory={(categoryId,categoryName) => handleDeleteCategory(categoryId,categoryName)}
            />
          </Col>

          {/* Subcategory Column */}
          <Col md={3} sm={4} xs={12} className="column">
            <h3 className="title-container">
              Subcategories
              {selectedCategory && (
                <Button variant="primary" size="sm" onClick={() => handleShowModal("addSubcategory",{selectedCategory})}>+ Add</Button>
              )}
            </h3>
            {selectedCategory && (
              <SubcategoryList
                subcategories={subcategories}
                setSelectedSubcategory={setSelectedSubcategory}
                selectedSubcategory={selectedSubcategory}
                handleShowUpdate={(subcategoryId,subcategoryName,categoryName) =>  handleUpdateSubCategory(subcategoryId, subcategoryName, categoryName)}
                handleDeleteSubcategory={(subcategoryId,subcategoryName) => handleDeleteSubCategory(subcategoryId,subcategoryName)}
              />
            )}
          </Col>

          {/* Documents Column */}
          <Col md={6} sm={4} xs={12} className="column">
            <h3 className="title-container">
              Documents
              {selectedCategory && selectedSubcategory && (
                <Button variant="primary" size="sm" onClick={() => handleShowModal("addDocument",{selectedCategory,selectedSubcategory,documents})}>+ Add</Button>
              )}
            </h3>
            {selectedCategory && selectedSubcategory && (
              <DocumentList
                documents={documents}
                handleShowUpdate={(document) => handleShowModal("updateDocument", { document })}
              />
            )}
          </Col>
        </Row>
      </div>

      {/* Modals */}
      <CategoryModal 
        show={showModal.addCategory} 
        handleClose={() => handleCloseModal("addCategory")} 
        action="Add" 
        setCategories={setCategories} 
        refreshCategories={fetchCategories}
      />
      <CategoryModal 
        show={showModal.updateCategory} 
        handleClose={() => handleCloseModal("updateCategory")} 
        action="Update" 
        setCategories={setCategories} 
        refreshCategories={fetchCategories}
        modalData={modalData}
      />
      <SubcategoryModal 
        show={showModal.addSubcategory} 
        handleClose={() => handleCloseModal("addSubcategory")} 
        action="Add" 
        refreshSubcategories={fetchSubcategories}
        modalData={modalData}
      />
      <SubcategoryModal 
        show={showModal.updateSubcategory} 
        handleClose={() => handleCloseModal("updateSubcategory")} 
        action="Update" 
        refreshSubcategories={fetchSubcategories}
        modalData={modalData}
      />
      <DocumentModal 
        show={showModal.addDocument} 
        handleClose={() => handleCloseModal("addDocument")} 
        action="Upload" 
        setDocuments={setDocuments} 
        selectedCategory={selectedCategory} 
        selectedSubcategory={selectedSubcategory} 
        refreshDocuments={fetchDocuments}
        modalData={modalData}
      />
      <DocumentModal 
        show={showModal.updateDocument} 
        handleClose={() => handleCloseModal("updateDocument")} 
        action="Update" 
        setDocuments={setDocuments} 
        documentData={modalData.document}
        selectedCategory={selectedCategory} 
        selectedSubcategory={selectedSubcategory} 
        refreshDocuments={fetchDocuments}
        modalData={modalData}
      />
    </>
  );
}

export default Settings;
