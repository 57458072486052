import React, { useState, useRef, useEffect } from "react";
import { Form, Button, Card } from "react-bootstrap";
import SideBarMobile from "../components/side-bar";
import { NavLink, Link } from "react-router-dom";
import SideBarMobileAnalyzeDoc from "../components/side-bar-analyzedoc";
import HeaderNavbar from "../components/headernavbar";
import KnowLedgeAIService from "../services/KnowledgeAIService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactMic } from 'react-mic';
import { saveAs } from 'file-saver';
import lamejs from 'lamejs';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
import { ThreeDots } from 'react-loader-spinner';
import SEO from "../components/seo";

const DocQa = () => {
  const [key, setKey] = useState("123456789");
  const [question, setQuestion] = useState("");
  const [prevQuestion, setPrevQuestion] = useState("");
  const [prev_answer, setPrevAnswer] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [showprogress, setShowProgress] = useState(false);
  const [showAudioprogress, setAudioShowProgress] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [progress, setProgress] = useState(0);
  const [recordedFile, setRecordedFile] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [fileName, setfileName] = useState(null);
  const [showfile, setShowFile] = useState(false);
  const [fileUploadReply, setfileUploadReply] = useState(null);

  const [audioChunks, setAudioChunks] = useState([]);

  const [sources, setSources] = useState(null);
  const [processedContentSource1, setProcessedContentSource1] = useState('');
  const [processedContentSource2, setProcessedContentSource2] = useState('');
  const [processedContentSource3, setProcessedContentSource3] = useState('');

  //method to handle the form submit
  const messageEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messageEndRef.current)
      messageEndRef.current.scrollTop = messageEndRef.current.scrollHeight;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!question) {
      toast.error('Please enter a question.s');
      return;
    }

    const conversation = {
      prev_question: prevQuestion,
      prev_answer: prev_answer,
      new_question: question,
    };

    //Creating json data
    const data = {
      key: key,
      conversation,
      clientid: 'acomp',
    };

    const div = document.createElement("div");

    div.innerHTML =
      `<div class='chat__boxanswer'>
      <div class='hedingcopyoption__flexd' id="queChat">
      <span class=''>
        <img src='images/search-home/user-icon.png' alt='' class='user-icon' />
         </span>
        <div>
          <p class='question'>`+ question + `</p>
          </p>
        </div>
      </div>
    </div>`;


    document.getElementById("chat").appendChild(div);
    setQuestion("");
    setShowProgress(true);
    const response = await KnowLedgeAIService.search(data);
   
      let answer = "";
      let tempsources = [];


      console.log("responseeee", response);
      console.log("sources ", response.sources);
      if (response?.sources) {
        setSources(response.sources);
      }
      //check if response contains answer key
      if (response?.answer) {
        answer = response.answer;

      }

      if (!response || !answer) {
        answer =
          "Sorry! But I could not find any relevant information to answer your question.";
      }
      const divContainer = document.createElement("div");

      divContainer.innerHTML =
        `<div class='chat__boxanswer'>
          <div class='hedingcopyoption__flexd' id="queChat">
          <span class=''>
            <img src='images/search-home/flag-icon.png' alt='' class='user-icon' />
             </span>
             <div class='fade-in-element bottom fade-in-bottom'>
              <p>`+ answer + `</p>
              </p>
            </div>
          </div>
        </div>`;


      document.getElementById("chat").appendChild(divContainer);
      setPrevQuestion(question);
      setPrevAnswer(answer);

      setQuestion("");    

    setShowProgress(false);
  };

  const handleFileChange = async (e) => {
    try {
      // e.preventDefault();
      const file = e.target.files[0];
      if (file) {

        const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        if (!allowedTypes.includes(file.type)) {
          // Handle case where file type is not allowed
          toast.error('Invalid file type. Please upload a PDF, DOC, or DOCX file.');

          return;
        }

        setSelectedFile(file);
        setShowProgress(true);
        // Pass the file data to the uploadFile method
        await KnowLedgeAIService.uploadFile(file);
        uploadFile(file);
        setfileName(file.name);

        setShowFile(true);
        setShowProgress(false);
        setPrevQuestion("");
        setPrevAnswer("");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle the error (e.g., display an error message to the user)
    }
  };

  const uploadFile = async (file) => {

    const totalPercentage = 100;
    let startPercent = 0;
    const interval = setInterval(() => {
      // Simulate progress
      startPercent++;
      const newProgress = (startPercent / totalPercentage) * 100;
      setProgress(newProgress);
      if (startPercent === totalPercentage) {
        // File upload is complete
        clearInterval(interval);
        setIsFilePicked(true);
        setfileUploadReply("File has been processed and is ready to use!");
      }
    }, 30);
  };
  
 



  const addAudioElement = async (audioBlob) => {
    try {
      console.log('Uploading audio...', audioBlob);

      if (!audioBlob) {
        console.error('No audio blob provided');
        return;
      }

      ////const url = URL.createObjectURL(audioBlob.blob);
      // console.log(url,"---");

      //const audioBlob = await fetch(blob).then(r => r.blob());
      //const audiofile = new File([audioBlob], "audiofile", { type: "audio/mp3" });
      const audioFile = new File([audioBlob], "filename.webm", { type: "audio/webm" });
      const formData = new FormData();
      formData.append("file", audioFile);
      formData.append('key', "123456789");
      setAudioShowProgress(true);
      
      const response = await KnowLedgeAIService.uploadAudioFile(formData);

      if (response.status === 200) {
        console.log('Audio uploaded successfully', response.data.transcription);
        setfileUploadReply("Audio has been processed and is ready to use!");
        callSearchAPI(response.data.transcription);

      } else {
        //const errorData = await response.json();
        console.error('Failed to upload audio:', response);
        setfileUploadReply("Failed to process audio");
      }
     
    } catch (error) {
      console.error('Error uploading audio:', error);
      setfileUploadReply("Error in uploading audio");
      setAudioShowProgress(false);
    }
   
    
  };


  useEffect(() => {
    scrollToBottom();
  }, [showprogress,showAudioprogress]);

  const handleInputChange = (e) => {
    setQuestion(e.target.value);
  };


  const handleStartRecording = () => {
    setIsRecording(true);
  };

  const handleStopRecording = () => {
    setIsRecording(false);
  };

  const onStop = (recordedBlob) => {
    setAudioChunks([...audioChunks, recordedBlob.blob]);
    console.log('recordedBlob is: ', recordedBlob);
  };

  const handleDownload = async () => {
    if (audioChunks.length === 0) {
      console.log('No audio recorded.');
      return;
    }

    const Mp3Encoder = lamejs.Mp3Encoder;
    const mp3encoder = new Mp3Encoder(1, 44100, 128); // mono 44.1kHz, 128 kbps

    let mp3Data = [];
    for (const chunk of audioChunks) {
      const buffer = chunk.buffer.slice(0);
      const samples = new Int16Array(buffer);

      const mp3buf = mp3encoder.encodeBuffer(samples);
      if (mp3buf.length > 0) {
        mp3Data.push(mp3buf);
      }
    }

    const finalMp3buf = mp3encoder.flush();
    if (finalMp3buf.length > 0) {
      mp3Data.push(finalMp3buf);
    }

    const blob = new Blob(mp3Data, { type: 'audio/mp3' });
    saveAs(blob, 'recorded_audio.mp3');
  };
  const callSearchAPI = async (text) => {
    //e.preventDefault();
    const conversation = {
      prev_question: prevQuestion,
      prev_answer: prev_answer,
      new_question: text,
    };

    //Creating json data
    const data = {
      key: '123456789',
      conversation,
      clientid: "acomp"
    };

   
    const div = document.createElement("div");

    div.innerHTML =
      `<div class='chat__boxanswer'>
      <div class='hedingcopyoption__flexd' id="queChat">
      <span class=''>
        <img src='images/search-home/user-icon.png' alt='' class='user-icon' />
         </span>
        <div>
          <p>`+ text + `</p>
          </p>
        </div>
      </div>
    </div>`;
    

    document.getElementById("chat").appendChild(div);
    setAudioShowProgress(false);
    setShowProgress(true);
    const response = await KnowLedgeAIService.search(data);
   
      let answer = "";
     
      if (response?.sources) {
        setSources(response.sources);
      }
      //check if response contains answer key
      if (response?.answer) {
        answer = response.answer;

      }

      if (!response || !answer) {
        answer =
          "Sorry! But I could not find any relevant information to answer your question.";
      }
      const divContainer = document.createElement("chat");

      divContainer.innerHTML =
        `<div class='chat__boxanswer'>
          <div class='hedingcopyoption__flexd' id="queChat">
          <span class=''>
            <img src='images/search-home/flag-icon.png' alt='' class='user-icon' />
             </span>
            <div class='fade-in-element bottom fade-in-bottom'>
              <p>`+ answer + `</p>
              </p>
            </div>
          </div>
        </div>`;


      document.getElementById("chat").appendChild(divContainer);

      setPrevQuestion(text);
      setPrevAnswer(answer);

      setQuestion("");  
      setShowProgress(false);
  };

  const [recordState, setRecordState] = useState(null);

  const start = () => {
    setRecordState(RecordState.START);
  };

  const stop = () => {
    setRecordState(RecordState.STOP);
  };


  return (
    <div>
      <SEO
        title='Lumyn - Generative AI Sandbox'
        description='Lumyn - Generative AI Sandbox'
        keywords='' />
      <main>
        <section>

          <div className="container-fluid p-0">
            <div className="d-flex mobile-nonedflex">
              <div className="">
                <div className="mobile-hidden">
                  <nav className="left__sidebar">
                    <div className="brand__image">
                      <img
                        src="/images/logo-brand.png"
                        alt="Band Logo"
                        className="brand__logo"
                      />
                    </div>
                    <div className="left-side-item">
                    <ul className="nav__bar__link">
                      <li>
                        <a className="active_link">
                          Upload a file and ask a question
                        </a>
                      </li>
                    </ul>
                  </div>
                  </nav>
                 
                </div>
              </div>

              <div className="" style={{ width: "100%" }}>
                <div className="right__sidebar">
                  <HeaderNavbar />

                  <div className="bg-changecontent knowledgesearch">
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-8">

                          <div class="custom-scrollbar" ref={messageEndRef}>
                            <div className="chat__section">
                              {/* {<div id="uploadfile"> </div>} */}
                              {/* {showfile && <div> {fileName} </div>} */}

                              {<div class='chat__boxanswer'>
                                <div class='sub__boxanswer'>
                                  <div className="chat__box"> {fileUploadReply} </div>
                                </div>
                              </div>
                              }

                              <div id="chat" className="chat__box">
                              {
                                  showAudioprogress &&
                                  <div class='chat__boxanswer'>
                                    <div class='hedingcopyoption__flexd' id="queChat">
                                      <span class=''>
                                        <img src='images/search-home/user-icon.png' alt='' class='user-icon' />
                                      </span>
                                      <div class="custom-loader"></div>
                                    </div>
                                  </div>
                                }
                                {showprogress &&

                                  <div class='chat__boxanswer'>
                                    <div class='hedingcopyoption__flexd' id="queChat">
                                      <span class=''>
                                        <img src='images/search-home/flag-icon.png' alt='' class='user-icon' />
                                      </span>
                                      <div class="custom-loader"></div>
                                    </div>
                                  </div>
                                }
                              </div>


                            </div>
                          </div>
                          <div class="row-20">
                            <Form onSubmit={handleSubmit}>
                              <div className="question-write">
                                <div className="col-lg-12 position-relative tooltip-input">
                                  <input
                                    type="text"
                                    class="form__control placeholder-text"
                                    placeholder="Write new question..."
                                    onChange={(e) => handleInputChange(e)}
                                    value={question}
                                  />
                                  {showTooltip && (
                                    <div className="tooltip">
                                      <div className="position-relative seacrh-icontooplist">
                                        <input
                                          type="text"
                                          class="form__controlsearch"
                                          id=""
                                          placeholder=""
                                        />
                                        <div className="icon__search">
                                          <i class="fa-solid fa-magnifying-glass"></i>
                                        </div>
                                      </div>
                                      
                                    </div>
                                  )}
                                  <div>
                                    {/* Your plus icon */}
                                    <div className="icon__plus pl-2">
                                      <label htmlFor="fileInput">
                                        <i
                                          className="fa fa-paperclip"
                                          aria-hidden="true"
                                        ></i>
                                      </label>
                                      <input
                                        type="file"
                                        id="fileInput" custom-scrollbar
                                        style={{ display: "none" }}
                                        onChange={(e) => handleFileChange(e)}
                                      />

                                    </div>

                                    {/* Hidden file input */}
                                  </div>
                                  <div className="icon__mic">
                                    <div className="icon__mic">
                                      <AudioRecorder
                                        onRecordingComplete={async (blob) => {
                                          addAudioElement(blob);
                                        }}

                                      />


                                    </div>

                                  </div>

                                  <div className="send__icon">
                                    <div className="d-flex with__gap-20px align-items-start">

                                      <div className="circle__static__done">
                                        <button type="submit">
                                          <i class="fa-solid fa-paper-plane"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Form>
                            <div
                              className="alert alert-progressbar-2"
                              style={{
                                display:
                                  selectedFile && progress != 100
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <div className="d-flex align-items-center gx-4">
                                <div className="progress-barimages">
                                  <img
                                    src="images/file-image.svg"
                                    alt=""
                                    className=""
                                  />
                                </div>
                                <div className="progressbar-word">
                                  <div className="task-progress">
                                    <p>{`Uploading file ${progress.toFixed(
                                      0
                                    )}%`}</p>
                                    <progress
                                      className="progress progress2"
                                      max="100"
                                      value={progress}
                                    ></progress>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 custom-scrollbar">
                          {sources && <h5 className="source-head5">Sources</h5>}
                          {
                            <>
                              {sources && sources.Source1 &&

                                <div className="source-box">
                                  <div className="source-head">
                                    <div>Source 1</div>
                                    {/* <a href="#"> Download <i class="fa-solid fa-download"></i> </a> */}
                                  </div>
                                  <div className="source-details">
                                    <p>{sources.Source1} </p>
                                  </div>

                                </div>
                              }

                              {sources && sources.Source2 &&

                                <div className="source-box">
                                  <div className="source-head">
                                    <div>Source 2</div>
                                    {/* <a href="#"> Download <i class="fa-solid fa-download"></i> </a> */}
                                  </div>
                                  <div className="source-details">
                                    <p>{sources.Source2} </p>
                                  </div>

                                </div>
                              }

                              {sources && sources.Source3 &&

                                <div className="source-box">
                                  <div className="source-head">
                                    <div>Source 3</div>
                                    {/* <a href="#"> Download <i class="fa-solid fa-download"></i> </a> */}
                                  </div>
                                  <div className="source-details">
                                    <p>{sources.Source3} </p>
                                  </div>

                                </div>
                              }
                            </>

                          }

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default DocQa;
