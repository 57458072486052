import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import KnowLedgeAIService from "../services/service"; // Import the API service

function CategoryModal({ show, handleClose, action, refreshCategories, modalData }) {
  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    console.log("modalData",modalData);
    if (modalData && modalData.categoryName) {
      setCategoryName(modalData.categoryName); // Set the existing name for editing
    } else {
      setCategoryName(""); // Reset for adding a new category
    }
  }, [modalData]);

  const handleSave = async () => {
    if (categoryName.trim() === "") {
      toast.error("Category name cannot be empty.");
      return;
    }

    const newCategory = { category: categoryName };

    try {
      if (modalData && modalData.categoryId) {
        // If `modalData.categoryId` exists, update the category
        await KnowLedgeAIService.Update_NHPCCategory(modalData.categoryId, newCategory);
        toast.success("Category updated successfully");
      } else {
        // Otherwise, add a new category
        await KnowLedgeAIService.Add_NHPCCategory(newCategory);
        toast.success("Category added successfully");
      }

      refreshCategories(); // Refresh the category list
      handleClose(); // Close the modal on successful save
    } catch (error) {
      console.error("Failed to save category:", error);
      toast.error("Failed to save category. Please try again.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{action} Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="categoryName">
          <Form.Label>Category Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter category name"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>        
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CategoryModal;
