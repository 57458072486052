//importing axios library
import axios from 'axios';

//defining the base url
const url = 'https://backend.lumyn.ai/';

//creating a class for the AI service
class FinanceAIService {
    
    //method to call the post api with the form data. Form data will have key, type, and image file
    static async extract(formData) {
        try {
            const response = await axios.post(url + 'extract', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    //Process Invoice
    static async processInvoice(formData) {
        try {
            const response = await axios.post(url + 'process_invoice/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
}

//exporting the class
export default FinanceAIService;
