import React, { useState, useRef } from "react";
import { Form, Button, Card } from "react-bootstrap";
import SideBarMobile from "../components/side-bar";
import { NavLink, Link } from "react-router-dom";
import HeaderNavbar from "../components/headernavbar";
import AIService from "../services/FinanceAIService";

const FinanceExtractEWayBill = () => {    
    const [isDragging, setIsDragging] = useState(false);
    //defining the state variables
    const [file, setFile] = useState("");
    const [key, setKey] = useState("123456789");
    const [type, setType] = useState("2");
    const [responsedata, setResponseData] = useState("");
    const [progress, setProgress] = useState(0);
    const [isFilePicked, setIsFilePicked] = useState(false);

    //method to handle the form submit
    const handleSubmit = async (e) => {
        
        e.preventDefault();
        //creating the form data
        const formData = new FormData();
        formData.append("file", file);
        formData.append("key", key);
        formData.append("type", type);

        console.log(file);

        //Change button text to "Loading..." while waiting for response
        const submitButton = document.querySelector('button[type="submit"]');
        submitButton.disabled = true;
        submitButton.innerText = "Processing...";

        //calling the extract method from AIService
        const response = await AIService.extract(formData);
        console.log(response);

        //Change button text back to "Proceed" after response
        submitButton.disabled = false;
        submitButton.innerText = "Proceed";

        //setting the data in the state
        setResponseData(response);
    };

    //Download the response data as a JSON file
    const downloadJSON = () => {
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(responsedata)], {
            type: "text/plain;charset=utf-8",
        });
        element.href = URL.createObjectURL(file);
        element.download = "response.json";
        document.body.appendChild(element);
        element.click();
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        //const files = e.dataTransfer.files;
        // Handle the dropped files (e.g., upload or process them)
        const file = e.dataTransfer.files[0];
        setFile(file);
        console.log('Dropped files:', e.dataTransfer.files[0]);
        if (file) {
            // Start the file upload process
            uploadFile(file);
        }        
    };

    //method to handle the file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFile(file);
        console.log('selected files:', e.target.files[0]);      
        if (file) {
            // Start the file upload process
            uploadFile(file);            
        }
    };

    const uploadFile = (file) => {
        const totalPercentage = 100;
        let startPercent = 0;
    
        const interval = setInterval(() => {
          // Simulate progress
          startPercent++;
          const newProgress = (startPercent / totalPercentage) * 100;
          setProgress(newProgress);
    
          if (startPercent === totalPercentage) {
            // File upload is complete
            clearInterval(interval);
            setIsFilePicked(true);
          }
        }, 30);
    }

    return (
        <div>
            <main>
                <section>
                    <div className="container-fluid p-0">
                        <div className="d-flex mobile-nonedflex">
                            <div className="">
                                <div className="mobile-hidden">
                                    <nav className="left__sidebar">
                                        <div className="brand__image">
                                            <img src="/images/logo-brand.png" alt="Band Logo" className="brand__logo" />
                                        </div>
                                        <ul className="nav__bar__link">
                                            <li>
                                                <Link to="/finance-process-invoice">Process Invoice</Link>
                                            </li>
                                            <li>
                                                <Link to="/finance-process-ewaybill" className="active_link">Process E-Way Bill</Link>
                                            </li>
                                            <li>
                                                <Link to="/finance-process-weighbridgeslip">Weighbridge Slip</Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>

                            <div
                                className=""
                                style={{
                                width: "100%"
                            }}>
                                <div className="right__sidebar">
                                    <HeaderNavbar/>
                                    <div className="fix-section">
                                        <div className="row gx-4">
                                            <div className="col-md-5 source-file-bgonly px-4">
                                                <Form onSubmit={handleSubmit}>
                                                    <h5 className="text-headingenter my-4">
                                                        Upload File to process E-Way Bill</h5>
                                                    <div
                                                        className={`file-upload ${isDragging
                                                        ? 'dragging'
                                                        : ''}`}
                                                        onDragEnter={handleDragEnter}
                                                        onDragOver={(e) => e.preventDefault()}
                                                        onDragLeave={handleDragLeave}
                                                        onDrop={handleDrop}>

                                                        <p className="position-relative">Drag and Drop files or folders, or 
                                                        <input type="file" placeholder="Browse" className="file-input" onChange={handleFileChange}/>
                                                            <span className="or-browse">
                                                                Browse</span>
                                                        </p>
                                                        <span className="sublinetag">maximum file size 20 mb</span>                                                        
                                                    </div>

                                                    <div className="alert alert-progressbar m-3" style={{ display: file ? 'block' : 'none' }}>
                                                        <div className="d-flex align-items-center gx-4">
                                                        <div className="progress-barimages">
                                                            <img src="images/file-image.svg" alt="" className="" />
                                                        </div>
                                                        <div className="progressbar-word">
                                                            <div className="task-progress">
                                                            <p>{`Uploading file ${progress.toFixed(0)}%`}</p>
                                                            <progress className="progress progress2" max="100" value={progress}></progress>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>

                                                    { isFilePicked ? (
                                                    <div className="mt-2 pt-lg-5"style={{textAlign: "right"}}>
                                                        <Button className="btn process-btn" type="submit">
                                                        Proceed
                                                        </Button>                                                        
                                                    </div>):null}
                                                </Form>
                                            </div>

                                            {/* Check if response data is not empty and reponsedata.status is success*/}
                                            {responsedata ? (

                                            <div className="col-md-7 px-3">
                                                <div className="">
                                                    <div className="sourcecard-body">
                                                        <div className="hedingcopyoption__flexd">
                                                            <div className="icon__copyedit">
                                                                <Link href="">
                                                                    <img src="images/copy-img.png" alt="" className="copy__icon"/>
                                                                </Link>
                                                                <Link href="">
                                                                    <img src="images/Download.png" alt="" className="edit__icon" onClick={downloadJSON}/>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        
                                                        <h3>EWay Bill</h3>
                                                        <div class="invoice-details mt-4">
                                                        <p><strong>E-Way Bill Number:</strong> {responsedata.e_way_bill_no}</p>
                                                        <p><strong>E-Way Bill Date:</strong> {responsedata.e_way_bill_date}</p>
                                                        <p><strong>Generated By:</strong> {responsedata.generated_by}</p>
                                                        <p><strong>Valid From:</strong> {responsedata.valid_from}</p>
                                                        <p><strong>Valid Until:</strong> {responsedata.valid_until}</p>
                                                        <p><strong>GSTIN of Supplier:</strong> {responsedata.gstin_of_supplier}</p>
                                                        <p><strong>Place of Dispatch:</strong> {responsedata.place_of_dispatch}</p>
                                                        <p><strong>GSTIN of Recipient:</strong> {responsedata.gstin_of_recipient}</p>
                                                        <p><strong>Place of Delivery:</strong> {responsedata.place_of_delivery}</p>
                                                        <p><strong>Document No:</strong> {responsedata.document_no}</p>
                                                        <p><strong>Document Date:</strong> {responsedata.document_date}</p>
                                                        <p><strong>Value of Goods:</strong> {responsedata.value_of_goods}</p>
                                                        <p><strong>Reason for Transportation:</strong> {responsedata.reason_for_transportation}</p>
                                                        <p><strong>Transporter:</strong> {responsedata.transporter}</p>
                                                        <p><strong>Mode:</strong> {responsedata.mode}</p>
                                                        <p><strong>Vehicle No:</strong> {responsedata.vehicle_no}</p>
                                                        <p><strong>From Location:</strong> {responsedata.from_location}</p>
                                                        <p><strong>Entered Date:</strong> {responsedata.entered_date}</p>
                                                        <p><strong>Entered By:</strong> {responsedata.entered_by}</p>
                                                    </div>
                                                    </div>
                                                </div>

                                            </div>
                                            ) : (
                                                <div>
                                                    <p> No data found </p>
                                                </div>
                                            )}
                                        </div>
                                    </div> 
                                </div> 
                            </div>
                        </div> 
                    </div> 
                </section>
            </main>
        </div>
    );
};

export default FinanceExtractEWayBill;
