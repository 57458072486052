import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import KnowLedgeAIService from "../services/service"; // Import the API service

function DocumentModal({
  show,
  handleClose,
  action,
  setDocuments,
  documentData,
  selectedCategory,
  selectedSubcategory,
  refreshDocuments,
  modalData = {}
}) {
  const [loading, setLoading] = useState(false); // Loading state
  const [docName, setDocName] = useState(documentData?.name || '');
  const [docType, setDocType] = useState(documentData?.type || 'Original');
  const [revisionDate, setRevisionDate] = useState(documentData?.revisionDate || '');
  const [file, setFile] = useState(null);
  const [parentFileName, setParentFileName] = useState(documentData?.parentFileName || '');
  const [parentFiles, setparentFiles] = useState([]);
  const [isFileSave, setisFileSave] = useState(false);

  useEffect(() => {
    if (documentData) {
      setDocName(documentData.name);
      setDocType(documentData.type);
      setRevisionDate(documentData.revisionDate);
      setParentFileName(documentData.parentFileName || '');
      setFile(null);
    } else {
      setDocName('');
      setDocType('Original');
      setRevisionDate('');
      setParentFileName('');
      setFile(null);
    }
  }, [documentData]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setDocName(selectedFile.name); // Auto-populate document name with file name
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!selectedCategory || !selectedSubcategory) {
      toast.error("Please select a category and subcategory.");
      return;
    } else if (!file) {
      toast.error("Please select file to upload.");
      return;
    } else if (!docName) {
      toast.error("Please enter a file name.");
      return;
    }

    try {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.ms-powerpoint",
        "image/jpeg",
        "image/png",
        "image/gif"
      ];

      // Validate the file type
      if (!allowedTypes.includes(file.type)) {
        toast.error("Invalid file type. Please upload a PDF, DOC, DOCX, or an image file.");
        return;
      }

      // Prepare the file for upload
      setisFileSave(true);  // Set loading state

      const formData = new FormData();
      formData.append("key", "123456789");
      formData.append("file", file);
      formData.append("filename", docName);
      formData.append("index", "multidoc-test-2");
      formData.append("clientID", "nhpc");
      formData.append("category", modalData.selectedCategory);
      formData.append("subcategory", modalData.selectedSubcategory);
      formData.append("isOriginal", docType === "Original" ? "true" : "false");
      formData.append("isRevision", docType === "Revision" ? "true" : "false");
      formData.append("isAmendment", docType === "Amendment" ? "true" : "false");
      formData.append("parentName", parentFileName || "");
      formData.append("revisionDate", revisionDate || "");

      // Upload the file
      const response = await KnowLedgeAIService.uploadFileinVectorDB(formData);

      // Check if the response message is successful
      if (response.message === "File successfully added to client's vector storage!") {
        // Create the document data to be saved in Firebase
        const firebaseDocumentData = {
          filename: docName,
          clientid: "nhpc",
          category: modalData.selectedCategory,
          subcategory: modalData.selectedSubcategory,
          isOriginal: docType === "Original" ? "true" : "false",
          isRevision: docType === "Revision" ? "true" : "false",
          isAmendment: docType === "Amendment" ? "true" : "false",
          parentName: parentFileName || "",  // Use empty string if not provided
          revisionDate: revisionDate || "",
          uploadedOn: new Date().toISOString().replace("T", " ").slice(0, 19)  // Format date to "YYYY-MM-DD HH:MM:SS"
        };

        // Save the document to Firebase
        await fetch(
          `https://lumyn-backend-default-rtdb.firebaseio.com/nhpc-documents.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(firebaseDocumentData)
          }
        );

        toast.success("Document successfully added to Firebase.");
      }
      else{
        toast.error(response.message);
      }

      // Reset form
      setDocName('');
      setDocType('Original');
      setRevisionDate('');
      setParentFileName('');
      setFile(null);
      refreshDocuments("");
      handleClose(); // Close the modal after successful upload

    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Failed to upload the file. Please try again.");
      setisFileSave(false); // Reset loading state on error
    }
    setLoading(false);
  };


  return (
    <>
      {loading && (
        <div className="loading-indicator">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{action} Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* File Upload */}
          <Form.Group>
            <Form.Label>File</Form.Label>
            <Form.Control type="file" onChange={handleFileChange} className="nhpcfile" />
          </Form.Group>

          {/* Document Name */}
          <Form.Group>
            <Form.Label>Document Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter document name"
              value={docName}
              onChange={(e) => setDocName(e.target.value)}
            />
          </Form.Group>

          {/* Document Type as Radio Buttons */}
          <Form.Group>
            <Form.Label>Document Type</Form.Label>
            <div>
              {['Original', 'Revision', 'Amendment'].map((type) => (
                <Form.Check
                  inline
                  type="radio"
                  key={type}
                  label={type}
                  value={type}
                  checked={docType === type}
                  onChange={(e) => setDocType(e.target.value)}
                />
              ))}
            </div>
          </Form.Group>

          {/* Parent File Name Dropdown - Only show if type is Revision or Amendment */}
          {docType !== 'Original' && (
            <Form.Group className="parentfile-list">
              <Form.Label>Parent File Name</Form.Label>
              <Form.Control
                as="select"
                value={parentFileName}
                onChange={(e) => setParentFileName(e.target.value)}
              >
                <option value="">Select parent file</option>
                {/* Filter for documents with type "Original" and map over them */}
                {(modalData.documents || [])
                  .filter((doc) => doc.type === "Original") // Filter to include only "Original" type documents
                  .map((doc, index) => (
                    <option key={index} value={doc.filename}>
                      {doc.filename}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>

          )}

          {/* Revision Date - Only show if type is Revision or Amendment */}
          {docType !== 'Original' && (
            <Form.Group>
              <Form.Label>Revision Date</Form.Label>
              <Form.Control
                type="date"
                value={revisionDate}
                onChange={(e) => setRevisionDate(e.target.value)}
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          
          <Button variant="primary" onClick={handleSave}>{action}</Button>
          <Button
            variant="secondary"
            onClick={() => {
              setDocName('');
              setFile(null);
              handleClose();
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DocumentModal;
