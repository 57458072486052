import React, { useState, useRef } from "react";
import { Form, Button, Card } from "react-bootstrap";
import SideBarMobile from "../components/side-bar";
import { NavLink, Link } from "react-router-dom";
import HeaderNavbar from "../components/headernavbar";
import HRAIService from "../services/HRAIService";
import { ThreeDots } from 'react-loader-spinner';
import { toast } from "react-toastify";
import SEO from "../components/seo";

const JobDescription = () => {
    //defining the state variables
    const [key, setKey] = useState("123456789");
    const [company, setCompany] = useState("Acompworld");
    const [location, setLocation] = useState("");
    const [title, setTitle] = useState("");
    const [jobtype, setJobType] = useState("");
    const [details, setDetails] = useState("");
    const [experience, setExperience] = useState("");
    const [companyprofile, setCompanyprofile] = useState("From a humble beginning in 2012, to becoming preferred software engineering partner for world-class brands, we have come a long way, guided by our vision “to be a globally recognized tech company driving positive transformation in organizations and people's lives”");


    const [responsedata, setResponseData] = useState("");
    const [progress, setProgress] = useState(0);
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [booleanstring, setBooleanString] = useState("");
    const [jobtitlekeywords, setJobTitleKeywords] = useState([""]);
    const [skillskeywords, setSkillsKeywords] = useState([""]);
    const [showprogress, setShowProgress] = useState(false);
    const [showprogressjt, setShowProgressJD] = useState(false);
    const [isClick, setIsCheck] = useState(false);



    const [jobSummaryChecked, setJobSummaryChecked] = useState(true);
    const [rolesChecked, setRolesChecked] = useState(true);
    const [requirementsChecked, setRequirementsChecked] = useState(true);
    const [additionalRequirementsChecked, setAdditionalRequirementsChecked] = useState(true);
    const [companyProfileChecked, setCompanyProfileChecked] = useState(true);

    const [isChecked, setIsChecked] = useState(true);
    const [section1, setSection1] = useState("Job Summary");
    const [section2, setSection2] = useState("Roles & Responsibilities");
    const [section3, setSection3] = useState("Requirements");
    const [section4, setSection4] = useState("Additional");
    const [section5, setSection5] = useState("Company Profile");

    const handleCheckboxChange = (checkedStateSetter, sectionSetter, sectionName, checked) => {
        checkedStateSetter(checked);
        sectionSetter(checked ? sectionName : '');
    };


    //method to handle the form submit
    const handleSubmit = async (e) => {

        e.preventDefault();

        if (title === "") {
            toast.error("Please enter Job Title");
        }
        else if (jobtype === "") {
            toast.error("Please select Job Type");
        }
        else if (section1 === "" && section2 === "" && section3 === "" && section4 === "" && section5 === "") {
            toast.error("Please select at least one section");
        }
        else {
            setShowProgressJD(true)

            setIsCheck(true);

            //combine section1, section2, section3, section4, section5 into one variable
            var section = "";
            if (section1 !== "") {
                if (section !== "")
                    section = section + ",'" + section1 + "'";
                else
                    section = "'" + section1 + "'";
            }
            if (section2 !== "") {
                if (section !== "")
                    section = section + ",'" + section2 + "'";
                else
                    section = "'" + section2 + "'";
            }
            if (section3 !== "") {
                if (section !== "")
                    section = section + ",'" + section3 + "'";
                else
                    section = "'" + section3 + "'";
            }
            if (section4 !== "") {
                if (section !== "")
                    section = section + ",'" + section4 + "'";
                else
                    section = "'" + section4 + "'";
            }
            if (section5 !== "") {
                if (section !== "")
                    section = section + ",'" + section5 + "'";
                else
                    section = "'" + section5 + "'";
            }


            console.log(section);

            const formData = new FormData();
            formData.append('key', "123456789");
            formData.append('client', 'Acompworld');
            formData.append('job_title', title);
            formData.append('location', location);
            formData.append('job_type', jobtype);
            formData.append('details', details);
            formData.append('categories', [section]);

            console.log("Pre formdata", formData);

            //Change button text to "Loading..." while waiting for response


            const submitButton = document.querySelector('button[type="submit"]');
            submitButton.disabled = true;
            submitButton.innerText = "Processing...";

            setShowProgressJD(true);


            //calling the extract method from AIService
            const response = await HRAIService.jobdescription(formData);
            console.log(response);

            //Change button text back to "Proceed" after response
            submitButton.disabled = false;
            submitButton.innerText = "Generate";

            console.log("response ", response[0].job_description);

            //setting the data in the state
            setResponseData(response[0].job_description);
            setShowProgressJD(false);

        }

    };

    //creating function to replace newlines with <br> in the report
    function replaceNewlinesWithBr(str) {
        return str?.split("\n").map((item, key) => {
            return (
                <span key={key}>
                    {item}
                    <br />
                </span>
            );
        });
    }
    //creating function to replace newlines with <br> in the report
    function replaceNewlinesWithBold(str) {
        return str?.split("\n").map((line, key) => {
            // Check if the line starts with "###" and bold the entire line if it does
            if (line.startsWith('###')) {
                return <span key={key}><strong>{line.slice(3)}</strong><br /></span>;
            }

            // Split the line by "**" to separate text that should be bolded
            const parts = line.split('**').map((part, index) => {
                // Bold the text if it was between "**"
                return index % 2 === 1 ? <strong key={index}>{part}</strong> : part;
            });

            return (
                <span key={key}>
                    {parts}
                    <br />
                </span>
            );
        });
    }


    const calBoolSearchAPI = async () => {


        //Creating json data
        const data = {
            key: '123456789',
            job_description: responsedata,

        };
        setShowProgress(true);
        const response = await HRAIService.CreateboolString(data);
        if (response) {
            console.log("Bool String", response);
            setBooleanString(response[0].boolean_string);
            setJobTitleKeywords(response[0].job_title_keywords);
            setSkillsKeywords(response[0].skills_keywords);

            console.log("Job Title Keywords", jobtitlekeywords);
            console.log("Skills Keywords", skillskeywords);
            console.log("Boolean String", booleanstring);


        }
        setShowProgress(false);

    };


    //Download the response data as a JSON file
    const downloadJSON = () => {
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(responsedata)], {
            type: "text/plain;charset=utf-8",
        });
        element.href = URL.createObjectURL(file);
        element.download = "response.json";
        document.body.appendChild(element);
        element.click();
    };

    const copyToSearchString = () => {
        navigator.clipboard.writeText(booleanstring)
            .then(() => {
                toast.success("Boolean search string copied to clipboard!");

            })
            .catch(err => {
                console.error('Could not copy text: ', err);
            });
    };
    const copyTitleKeywords = () => {
        const textToCopy = jobtitlekeywords.join(', ');
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                toast.success("Job title keywords copied to clipboard!");
            })
            .catch(err => {
                console.error('Could not copy text: ', err);
            });
    };
    const copySkillsKeywords = () => {
        const textToCopy = skillskeywords.join(', ');
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                toast.success("Skills keywords copied to clipboard!");
            })
            .catch(err => {
                console.error('Could not copy text: ', err);
            });
    };

    return (
        <div>
            <SEO
                title='Lumyn - Generative AI Sandbox'
                description='Lumyn - Generative AI Sandbox'
                keywords='' />
            <main>
                <section>
                    <div className="container-fluid p-0">
                        <div className="d-flex mobile-nonedflex">
                            <div className="">

                                <div className="mobile-hidden">
                                    <nav className="left__sidebar">
                                        <div className="brand__image">
                                            <img src="/images/logo-brand.png" alt="Band Logo" className="brand__logo" />
                                        </div>
                                        <div className="left-side-item">
                                            <ul className="nav__bar__link">
                                                <li>
                                                    <a className="active_link">Create Job Description</a>
                                                </li>
                                                <li>
                                                    <NavLink to="/humanresource-salary-data">Get Salary</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/humanresource-resume-analysis">Resume Analysis</NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>

                            <div
                                className=""
                                style={{
                                    width: "100%"
                                }}>
                                <div className="right__sidebar">

                                    <HeaderNavbar />

                                    <div className="fix-section">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="bg-color-change">
                                                    <Form onSubmit={handleSubmit}>
                                                        <div className='chat__section '>
                                                            <div className=''>
                                                                <h5 className="text-headingenter pt-3">
                                                                    Enter Details</h5>
                                                                <div className='chat__boxquestion'>

                                                                    <div className='paddingpx'>
                                                                        <input
                                                                            type='text'
                                                                            name=''
                                                                            id=''
                                                                            className='tittle__input'
                                                                            placeholder='Job Title'
                                                                            onChange={(e) => setTitle(e.target.value)}
                                                                        />
                                                                        <input
                                                                            type='text'
                                                                            name=''
                                                                            id=''
                                                                            className='tittle__input'
                                                                            placeholder='Location'
                                                                            onChange={(e) => setLocation(e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className='context-area'>
                                                                    <textarea
                                                                        className='context__textarea'
                                                                        placeholder='You can write here anything specific about the job' onChange={(e) => setDetails(e.target.value)}></textarea>
                                                                </div>
                                                            </div>
                                                            <div className='width-control'>
                                                                <div className='list-dflex'>
                                                                    <div className='tone'>
                                                                        <p className='tone__text'>Job Type</p>
                                                                        <ul>
                                                                            <li
                                                                                onClick={(e) => setJobType("Remote")}
                                                                                className={jobtype === "Remote"
                                                                                    ? 'tabcolor sub-button'
                                                                                    : "sub-button"}>Remote</li>
                                                                            <li
                                                                                onClick={(e) => setJobType("Hybrid")}
                                                                                className={jobtype === "Hybrid"
                                                                                    ? 'tabcolor sub-button'
                                                                                    : "sub-button"}>Hybrid</li>
                                                                            <li
                                                                                onClick={(e) => setJobType("On-site")}
                                                                                className={jobtype === "On-site"
                                                                                    ? 'tabcolor sub-button'
                                                                                    : "sub-button"}>On-site</li>
                                                                        </ul>
                                                                    </div>

                                                                </div>

                                                            </div>


                                                            <div className="px-4">
                                                                <a
                                                                    class="advancetext-dropdown"
                                                                    data-bs-toggle="collapse"
                                                                    href="#collapseExample"
                                                                    role="button"
                                                                    aria-expanded="true"
                                                                    aria-controls="collapseExample">
                                                                    Advanced Options
                                                                    <i class="fa-solid fa-chevron-down"></i>
                                                                </a>

                                                                <div class="collapse" id="collapseExample">
                                                                    <div class="job-select-category">
                                                                        <h6>Include in Output</h6>
                                                                        <ul className="job-checkbox">
                                                                            <li>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="job-summary"
                                                                                    id="Job-Summary1"
                                                                                    name="Job Summary"
                                                                                    value="Job Summary"
                                                                                    checked={jobSummaryChecked}
                                                                                    onChange={(e) => handleCheckboxChange(setJobSummaryChecked, setSection1, "Job Summary", e.target.checked)}
                                                                                />
                                                                                <label htmlFor="Job-Summary1" className="jobsummarylabel">Job Summary</label><br />
                                                                            </li>
                                                                            <li>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="job-summary"
                                                                                    id="Job-Summary2"
                                                                                    name="Roles & Responsibilities"
                                                                                    value="Roles & Responsibilities"
                                                                                    checked={rolesChecked}
                                                                                    onChange={(e) => handleCheckboxChange(setRolesChecked, setSection2, "Roles & Responsibilities", e.target.checked)}
                                                                                />
                                                                                <label htmlFor="Job-Summary2" className="jobsummarylabel">Roles & Responsibilities</label><br />
                                                                            </li>
                                                                            <li>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="job-summary"
                                                                                    id="Job-Summary3"
                                                                                    name="Requirements"
                                                                                    value="Requirements"
                                                                                    checked={requirementsChecked}
                                                                                    onChange={(e) => handleCheckboxChange(setRequirementsChecked, setSection3, "Requirements", e.target.checked)}
                                                                                />
                                                                                <label htmlFor="Job-Summary3" className="jobsummarylabel">Requirements</label><br />
                                                                            </li>
                                                                            <li>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="job-summary"
                                                                                    id="Job-Summary4"
                                                                                    name="Additional Requirements"
                                                                                    value="Additional Requirements"
                                                                                    checked={additionalRequirementsChecked}
                                                                                    onChange={(e) => handleCheckboxChange(setAdditionalRequirementsChecked, setSection4, "Additional Requirements", e.target.checked)}
                                                                                />
                                                                                <label htmlFor="Job-Summary4" className="jobsummarylabel">Additional Requirements</label><br />
                                                                            </li>
                                                                            <li>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="job-summary"
                                                                                    id="Job-Summary5"
                                                                                    name="Company Profile"
                                                                                    value="Company Profile"
                                                                                    checked={companyProfileChecked}
                                                                                    onChange={(e) => handleCheckboxChange(setCompanyProfileChecked, setSection4, "Company Profile", e.target.checked)}
                                                                                />
                                                                                <label htmlFor="Job-Summary5" className="jobsummarylabel">Company Profile</label><br />
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='px-4 button-genration'>
                                                                <button className='genration-btn' type="submit">Generate</button>
                                                            </div>
                                                            <div className="blank50">
                                                                &nbsp;
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                            {/* Check if response data is not empty and reponsedata.status is success*/}

                                            <div className="col-md-6">

                                                <div className="bg-color-change">
                                                    <h5 className="text-headingenter pt-3">
                                                        {isClick ? "Job Description" : ""}</h5>
                                                    <div className=" width-controlJD">
                                                        <div className="jobdescriptionContent my-custom-scrollbar">
                                                            {showprogressjt ? (

                                                                <div class='hedingcopyoption__flexd' id="queChat">
                                                                    <span class=''>
                                                                        <img src='images/search-home/flag-icon.png' alt='' class='user-icon' />
                                                                    </span>
                                                                    <div class="custom-loader"></div>
                                                                </div>) :

                                                                replaceNewlinesWithBold(responsedata)

                                                            }



                                                        </div>
                                                        <div className='px-4 button-genration'>
                                                            {responsedata ? (
                                                                <button className='genration-btn' type="submit" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={calBoolSearchAPI} >Create Search String</button>
                                                            ) : (<><div className="blank40"></div></>)}

                                                        </div>

                                                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-lg">
                                                                <div className="modal-content popup-bg-color-change">
                                                                    <div className="modal-header border-bottom">
                                                                        <h5 className="modal-title color-white" id="exampleModalLabel">{title}</h5>
                                                                        <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <div className="jobdescriptionContent my-custom-scrollbar">
                                                                            {showprogress ? (
                                                                                <>
                                                                                    <ThreeDots
                                                                                        visible={true}
                                                                                        height="80"
                                                                                        width="80"
                                                                                        color="#4fa94d"
                                                                                        radius="9"
                                                                                        ariaLabel="three-dots-loading"
                                                                                        wrapperStyle={{}}
                                                                                        wrapperClass="progress-bar-center"
                                                                                    />

                                                                                </>

                                                                            ) :
                                                                                <>
                                                                                    <strong className="mt10"> Boolean Search String
                                                                                        <div className='icon__copyedit fltright' onClick={copyToSearchString}>
                                                                                            <img src='images/search-home/copy-icon.svg' alt='Copy' className='copy__icon' />

                                                                                        </div>
                                                                                    </strong>
                                                                                    <p className="">{booleanstring}</p>
                                                                                    <strong className="mt10"> Job Title Keywords
                                                                                        <div className='icon__copyedit fltright' onClick={copyTitleKeywords}>
                                                                                            <img src='images/search-home/copy-icon.svg' alt='Copy' className='copy__icon' />

                                                                                        </div>
                                                                                    </strong>
                                                                                    <p className="">
                                                                                        {jobtitlekeywords.map((keyword, index) => (
                                                                                            <span key={index} className="">{keyword}, </span>
                                                                                        ))}
                                                                                    </p>

                                                                                    <strong className="mt10"> Skills Keywords
                                                                                        <div className='icon__copyedit fltright' onClick={copySkillsKeywords}>
                                                                                            <img src='images/search-home/copy-icon.svg' alt='Copy' className='copy__icon' />

                                                                                        </div>
                                                                                    </strong>

                                                                                    <p className="">
                                                                                        {skillskeywords.map((keyword, index) => (
                                                                                            <span key={index} className="" >{keyword},  </span>
                                                                                        ))}
                                                                                    </p>
                                                                                </>
                                                                            }




                                                                        </div>
                                                                    </div>
                                                                    <div className="modal-footer border-top">
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>



                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default JobDescription;
