import React, { useState, useRef, useEffect } from "react";
import { Form, Button, Card, Modal, Row, Col } from "react-bootstrap";
import SideBarMobile from "../../components/side-bar";
import { NavLink, Link } from "react-router-dom";
import SideBarMobileAnalyzeDoc from "../../components/side-bar-analyzedoc";
import HeaderNavbar from "./NhpcHeadernavbar";
import KnowLedgeAIService from "../services/service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactMic } from "react-mic";
import { saveAs } from "file-saver";
import lamejs from "lamejs";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import { ThreeDots } from "react-loader-spinner";
import SEO from "../../components/seo";
import axios from "axios";
import ProgressButton from "react-progress-button";
import FileModal from './filemodal';

const NhpcKnowLedgeSearch = () => {
  const [key, setKey] = useState("123456789");
  const [question, setQuestion] = useState("");
  const [prevQuestion, setPrevQuestion] = useState("");
  const [prev_answer, setPrevAnswer] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [showprogress, setShowProgress] = useState(false);
  const [showAudioprogress, setAudioShowProgress] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [progress, setProgress] = useState(0);
  const [recordedFile, setRecordedFile] = useState(null);
  const [isRecording, setIsRecording] = useState(false);

  const [showfile, setShowFile] = useState(false);
  const [fileUploadReply, setfileUploadReply] = useState(null);

  const [audioChunks, setAudioChunks] = useState([]);

  const [sources, setSources] = useState(null);
  const [isFileSave, setisFileSave] = useState(false);


  const [Categorydata, setCategoryData] = useState([]);
  const [Subcategorydata, setSubcategoryData] = useState([]);

  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [fileName, setfileName] = useState("");
  const [newCategory, setNewCategory] = useState("");

  const [categoryFileList, setCategoryFileList] = useState([]);
  const [CategoryFileData, setCategoryFileData] = useState([]);

  const [sourceFile1, setSourceFile1] = useState('');
  const [sourceFile2, setSourceFile2] = useState('');
  const [sourceFile3, setSourceFile3] = useState('');

  const [showFullContent, setShowFullContent] = useState(false);
  //method to handle the form submit
  const messageEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messageEndRef.current)
      messageEndRef.current.scrollTop = messageEndRef.current.scrollHeight;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!question) {
      toast.error("Please enter a question.");
      return;
    }

    const conversation = {
      prev_question: prevQuestion,
      prev_answer: prev_answer,
      new_question: question,
    };

    //Creating json data
    const data = {
      key: key,
      conversation,
      clientID: "nhpc",
      category: category,
      subcategory: subcategory,
      index:"multidoc-test-2",
      model:"2"
    };

    console.log("data", data);
    const div = document.createElement("div");

    div.innerHTML =
      `<div class='chat__boxanswer'>
      <div class='hedingcopyoption__flexd' id="queChat">
      <span class=''>
        <img src='images/search-home/user-icon.png' alt='' class='user-icon' />
         </span>
        <div>
          <p class='question'>` +
      question +
      `</p>
          </p>
        </div>
      </div>
    </div>`;

    document.getElementById("chat").appendChild(div);
    setQuestion("");
    setShowProgress(true);
    const response = await KnowLedgeAIService.searchMultiDoc(data);

    let answer = "";
    let tempsources = [];

    console.log("responseeee", response);
    console.log("sources ", response.sources);

    if (response?.sources) {
      setSources(response.sources);

      if (response.sources.Source1) {
        setSourceFile1(response.sources.Source1.doc_title);
      }
      if (response.sources.Source2) {
        setSourceFile2(response.sources.Source2.doc_title);
      }

      if (response.sources.Source3) {
        setSourceFile3(response.sources.Source3.doc_title);
      }

    }
    //check if response contains answer key
    if (response?.answer) {
      answer = response.answer;
    }

    if (!response || !answer) {
      answer =
        "Sorry! But I could not find any relevant information to answer your question.";
    }
    const divContainer = document.createElement("div");

    divContainer.innerHTML =
      `<div class='chat__boxanswer'>
          <div class='hedingcopyoption__flexd' id="queChat">
          <span class=''>
            <img src='images/search-home/flag-icon.png' alt='' class='user-icon' />
             </span>
             <div class='fade-in-element bottom fade-in-bottom'>
              <div class='answer'>` +
              convertTextToHtmlString(answer) +
              `</div>
              </p>
            </div>
          </div>
        </div>`;

    document.getElementById("chat").appendChild(divContainer);
    setPrevQuestion(question);
    setPrevAnswer(answer);

    setQuestion("");

    setShowProgress(false);
  };


  const addAudioElement = async (audioBlob) => {
    try {
      console.log("Uploading audio...", audioBlob);

      if (!audioBlob) {
        console.error("No audio blob provided");
        return;
      }

      ////const url = URL.createObjectURL(audioBlob.blob);
      // console.log(url,"---");

      //const audioBlob = await fetch(blob).then(r => r.blob());
      //const audiofile = new File([audioBlob], "audiofile", { type: "audio/mp3" });
      const audioFile = new File([audioBlob], "filename.webm", {
        type: "audio/webm",
      });
      const formData = new FormData();
      formData.append("file", audioFile);
      formData.append("key", "123456789");
      setAudioShowProgress(true);

      const response = await KnowLedgeAIService.uploadAudioFile(formData);

      if (response.status === 200) {
        console.log("Audio uploaded successfully", response.data.transcription);
        setfileUploadReply("Audio has been processed and is ready to use!");
        callSearchAPI(response.data.transcription);
      } else {
        //const errorData = await response.json();
        console.error("Failed to upload audio:", response);
        setfileUploadReply("Failed to process audio");
      }
    } catch (error) {
      console.error("Error uploading audio:", error);
      setfileUploadReply("Error in uploading audio");
      setAudioShowProgress(false);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [showprogress, showAudioprogress]);

  const handleInputChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleStartRecording = () => {
    setIsRecording(true);
  };

  const handleStopRecording = () => {
    setIsRecording(false);
  };

  const onStop = (recordedBlob) => {
    setAudioChunks([...audioChunks, recordedBlob.blob]);
    console.log("recordedBlob is: ", recordedBlob);
  };

  const handleDownload = async () => {
    if (audioChunks.length === 0) {
      console.log("No audio recorded.");
      return;
    }

    const Mp3Encoder = lamejs.Mp3Encoder;
    const mp3encoder = new Mp3Encoder(1, 44100, 128); // mono 44.1kHz, 128 kbps

    let mp3Data = [];
    for (const chunk of audioChunks) {
      const buffer = chunk.buffer.slice(0);
      const samples = new Int16Array(buffer);

      const mp3buf = mp3encoder.encodeBuffer(samples);
      if (mp3buf.length > 0) {
        mp3Data.push(mp3buf);
      }
    }

    const finalMp3buf = mp3encoder.flush();
    if (finalMp3buf.length > 0) {
      mp3Data.push(finalMp3buf);
    }

    const blob = new Blob(mp3Data, { type: "audio/mp3" });
    saveAs(blob, "recorded_audio.mp3");
  };
  const callSearchAPI = async (text) => {
    //e.preventDefault();
    const conversation = {
      prev_question: prevQuestion,
      prev_answer: prev_answer,
      new_question: text,
    };

    //Creating json data
    const data = {
      key: key,
      conversation,
      clientID: "nhpc",
      category: category,
      subcategory: subcategory,
      index:"multidoc-test-2",
      model:"2"
    };

    const div = document.createElement("div");

    div.innerHTML =
      `<div class='chat__boxanswer'>
      <div class='hedingcopyoption__flexd' id="queChat">
      <span class=''>
        <img src='images/search-home/user-icon.png' alt='' class='user-icon' />
         </span>
        <div>
          <p>` +
      text +
      `</p>
          </p>
        </div>
      </div>
    </div>`;

    document.getElementById("chat").appendChild(div);
    setAudioShowProgress(false);
    setShowProgress(true);
    const response = await KnowLedgeAIService.searchMultiDoc(data);

    let answer = "";

    if (response?.sources) {
      setSources(response.sources);

      if (response.sources.Source1) {
        setSourceFile1(response.sources.Source1.doc_title);
      }
      if (response.sources.Source2) {
        setSourceFile2(response.sources.Source2.doc_title);
      }
      if (response.sources.Source3) {
        setSourceFile3(response.sources.Source3.doc_title);
      }
    }
    //check if response contains answer key
    if (response?.answer) {
      answer = response.answer;
    }

    if (!response || !answer) {
      answer =
        "Sorry! But I could not find any relevant information to answer your question.";
    }
    const divContainer = document.createElement("chat");

    divContainer.innerHTML =
      `<div class='chat__boxanswer'>
          <div class='hedingcopyoption__flexd' id="queChat">
          <span class=''>
            <img src='images/search-home/flag-icon.png' alt='' class='user-icon' />
             </span>
            <div class='fade-in-element bottom fade-in-bottom'>
              <p>` +
              convertTextToHtmlString(answer) +
      `</p>
              </p>
            </div>
          </div>
        </div>`;

    document.getElementById("chat").appendChild(divContainer);

    setPrevQuestion(text);
    setPrevAnswer(answer);

    setQuestion("");
    setShowProgress(false);
  };

  const [recordState, setRecordState] = useState(null);

  const start = () => {
    setRecordState(RecordState.START);
  };

  const stop = () => {
    setRecordState(RecordState.STOP);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchSubcategories();
  }, [category]);

  useEffect(() => {
    fetchDocuments();
  }, [category,subcategory]);


  const fetchCategories = async () => {
    const response = await KnowLedgeAIService.List_NHPCCategory();
    const categories = Object.values(response.data).map(item => item.category);
    console.log("categories",categories);
    setCategoryData(categories);
    setCategory(categories[0]);
  };

  const fetchSubcategories = async () => {
    const response = await KnowLedgeAIService.List_NHPCSubCategory(category);
    const subcategories = Object.values(response.data).map(item => item.subcategory);
    console.log("subcategories",subcategories);
    setSubcategoryData(subcategories);
    setSubcategory(subcategories[0]);
  };

  
  const fetchDocuments = async () => {
    if (category && subcategory) {
      try {
        // Call the API to get documents filtered by category and subcategory
        const response = await KnowLedgeAIService.List_NHPCDocuments(category, subcategory);

        // Log the entire response to check its structure
        //console.log("API response:", response);

        // Check if response exists and is not empty
        if (response && Object.keys(response).length > 0) {
          //console.log("Response data exists. Processing documents...");

          // Convert response object to an array and map the fields as needed
          // Use Object.entries to get both the id (key) and category data (value)
          const documentArray = Object.entries(response).map(([id, doc]) => ({
            id: id, // Set the id from the key
            filename: doc.filename,
            type: doc.isOriginal === "true" ? "Original" : doc.isRevision === "true" ? "Revision" : doc.isAmendment === "true" ? "Amendment" : "Unknown",
            revisionDate: doc.revisionDate || "N/A", // Default to "N/A" if revisionDate is empty
            uploadedOn: doc.uploadedOn || "N/A" // Default to "N/A" if uploadedOn is empty
          }));

          // Log the processed document array
          //console.log("Processed Document Array:", documentArray);

          // Set the documents state with the processed array
          setCategoryFileList(documentArray);
        } else {
          console.log("No documents found for the selected category and subcategory.");
          setCategoryFileList([]); // Set documents to an empty array if no data found
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
        toast.error("Failed to load documents");
      }
    } else {
      // Clear documents if either category or subcategory is deselected
      setCategoryFileList([]);
    }    
  };


  const handleInputFileName = (e) => {
    setfileName(e.target.value);
  };

  // Initial selection is the ID of the first option
  const initialSelection = Categorydata?.id;
  const [selectedCatogry, setSelectedCatogry] = useState(initialSelection);
  useEffect(() => {
    setSelectedCatogry(initialSelection);
  }, [initialSelection]);
  // Set up state with the initial selection
  // Handle dropdown change
  const handleCategory = (event) => {
    setCategory(event.target.value);
    fetchSubcategories();
    fetchDocuments();
  };

  const handleSubcategory = (event) => {
    setSubcategory(event.target.value);
    fetchDocuments();
  };

  // Function to truncate the item text if it exceeds 30 characters
  const truncateItem = (text) => {
    return text.length > 30 ? text.substring(0, 30) + '...' : text;
  };

  function replaceNewlinesWithBr(str) {
    const convertToHTML = (text) => {
        // Replace ** ** with <h5> </h5>
        text = text.replace(/\*\*(.*?)\*\*/g, '<h5>$1</h5>');
        // Replace * * with <p> </p>
        text = text.replace(/\*(.*?)\*/g, '<p>$1</p>');
        // Replace newlines with <br />
        text = text.replace(/\n/g, '<br />'); 
        
         // Replace more than one <br> with one <br>
         text = text.replace(/(<br\s*\/?>\s*){2,}/g, '<br />');

        return text;
    };

    return convertToHTML(str); // Return the HTML string
  }

  // Function to convert text to an HTML string
  const convertTextToHtmlString = (text) => {
    const lines = text.split('\n');
    let htmlString = '';
    let currentList = null;
  
    lines.forEach((line) => {
      if (line.trim() === '') {
        // Skip empty lines
        return;
      }
  
      // Handle H1 headings
      if (line.startsWith('# ')) {
        if (currentList) {
          htmlString += currentList.join('') + '</ul>';
          currentList = null;
        }
        htmlString += `<h1>${line.substring(2)}</h1>`;
      }
      // Handle H2 headings
      else if (line.startsWith('## ')) {
        if (currentList) {
          htmlString += currentList.join('') + '</ul>';
          currentList = null;
        }
        htmlString += `<h2>${line.substring(3)}</h2>`;
      }
      // Handle H3 headings
      else if (line.startsWith('### ')) {
        if (currentList) {
          htmlString += currentList.join('') + '</ul>';
          currentList = null;
        }
        htmlString += `<h3>${line.substring(4)}</h3>`;
      }
      // Handle H4 headings
      else if (line.startsWith('#### ')) {
        if (currentList) {
          htmlString += currentList.join('') + '</ul>';
          currentList = null;
        }
        htmlString += `<h4>${line.substring(5)}</h4>`;
      }
      // Handle bold text (inline and standalone)
      else if (line.includes('**')) {
        const boldProcessedLine = line.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
        if (currentList) {
          htmlString += currentList.join('') + '</ul>';
          currentList = null;
        }
        htmlString += `<p>${boldProcessedLine}</p>`;
      }
      // Handle list items
      else if (line.startsWith('-')) {
        if (!currentList) {
          currentList = [];
          htmlString += '<ul>';
        }
        currentList.push(`<li>${line.substring(2).trim()}</li>`);
      }
      // Handle indented paragraphs (assuming indentation signifies paragraph text)
      else if (line.startsWith('    ')) {
        if (currentList) {
          htmlString += currentList.join('') + '</ul>';
          currentList = null;
        }
        htmlString += `<p>${line.trim()}</p>`;
      }
      // Default to regular paragraphs
      else {
        if (currentList) {
          htmlString += currentList.join('') + '</ul>';
          currentList = null;
        }
        htmlString += `<p>${line}</p>`;
      }
    });
  
    // Close any remaining open list
    if (currentList) {
      htmlString += currentList.join('') + '</ul>';
    }
  
    return htmlString;
  };    

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const getShortContent = (content) => {
    if (content.length > 150) {
      return content.substring(0, 150) + '...';
    }
    return content;
  };


  return (
    <div>
      <SEO
        title="Lumyn - Generative AI Sandbox"
        description="Lumyn - Generative AI Sandbox"
        keywords=""
      />

      <main>
        <HeaderNavbar />
        <section>
          <div className="container-fluid p-0">
            <div className="d-flex mobile-nonedflex">
              <div className="">
                <div className="mobile-hidden">
                  <nav className="left__sidebar">
                    <div className="left-side-item">
                      <div>
                        <h6 className="smallheading">Category</h6>
                        <select
                          value={category}
                          onChange={
                            (e) => handleCategory(e)
                          }
                          className="select-dropdown nhpc"
                        >

                          {Categorydata.map((opt) => (
                            <option key={opt} value={opt}>
                              {opt}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <h6 className="smallheading">Subcategory</h6>
                        <select
                          value={subcategory}
                          onChange={
                            (e) => handleSubcategory(e)
                          }
                          className="select-dropdown nhpc"
                        >

                          {Subcategorydata.map((opt) => (
                            <option key={opt} value={opt}>
                              {opt}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="ulItem">
                        <h6 className="smallheading">Documents</h6>
                        <ul class="nav__bar__link nhpc">
                          {categoryFileList.map((item, index) => (
                            <li key={index}>
                              {/*<a href={"https://backend.lumyn.ai/nhpcfiles/"+item.filename} title={item.filename} className="multidoclink"> <i class="fa fa-file fileicon"></i> {truncateItem(item.filename)}</a>*/}
                              <FileModal filename={item.filename} filepath={"https://backend.lumyn.ai/nhpcfiles/"+item.filename} pageNumber={0} />
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </nav>

                </div>
              </div>

              <div className="" style={{ width: "100%" }}>
                <div className="right__sidebar">                 

                  <div className="bg-changecontent knowledgesearch">
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-8">
                          <div class="custom-scrollbar nhpc" ref={messageEndRef}>
                            <div className="chat__section">
                              {/* {<div id="uploadfile"> </div>} */}
                              {/* {showfile && <div> {fileName} </div>} */}

                              {
                                <div class="chat__boxanswer">
                                  <div class="sub__boxanswer">
                                    <div className="chat__box">
                                      {" "}
                                      {fileUploadReply}{" "}
                                    </div>
                                  </div>
                                </div>
                              }

                              <div id="chat" className="chat__box">
                                {showAudioprogress && (
                                  <div class="chat__boxanswer">
                                    <div
                                      class="hedingcopyoption__flexd"
                                      id="queChat"
                                    >
                                      <span class="">
                                        <img
                                          src="images/search-home/user-icon.png"
                                          alt=""
                                          class="user-icon"
                                        />
                                      </span>
                                      <div class="custom-loader"></div>
                                    </div>
                                  </div>
                                )}
                                {showprogress && (
                                  <div class="chat__boxanswer">
                                    <div
                                      class="hedingcopyoption__flexd"
                                      id="queChat"
                                    >
                                      <span class="">
                                        <img
                                          src="images/search-home/flag-icon.png"
                                          alt=""
                                          class="user-icon"
                                        />
                                      </span>
                                      <div class="custom-loader"></div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div class="row-20">
                            <Form onSubmit={handleSubmit}>
                              <div className="question-write">
                                <div className="col-lg-12 position-relative tooltip-input">
                                  <input
                                    type="text"
                                    class="form__control placeholder-text"
                                    placeholder="Write new question..."
                                    onChange={(e) => handleInputChange(e)}
                                    value={question}
                                  />
                                  {showTooltip && (
                                    <div className="tooltip">
                                      <div className="position-relative seacrh-icontooplist">
                                        <input
                                          type="text"
                                          class="form__controlsearch"
                                          id=""
                                          placeholder=""
                                        />
                                        <div className="icon__search">
                                          <i class="fa-solid fa-magnifying-glass"></i>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <div>{/* Hidden file input */}</div>
                                  <div className="icon__mic">
                                    <div className="icon__mic">
                                      <AudioRecorder
                                        onRecordingComplete={async (blob) => {
                                          addAudioElement(blob);
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="send__icon">
                                    <div className="d-flex with__gap-20px align-items-start">
                                      <div className="circle__static__done">
                                        <button type="submit">
                                          <i class="fa-solid fa-paper-plane"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          </div>
                        </div>
                        <div class="col-lg-4 custom-scrollbar nhpc sources">
                          {sources && <h5 className="source-head5">Sources</h5>}
                          {
                            <>
                              {sources && sources.Source1 && (
                                <div className="source-box">
                                  <div className="source-head">
                                    {/*<div>File 1: {sourceFile1}</div>*/}
                                    {/* <a href="#"> Download <i class="fa-solid fa-download"></i> </a> */}
                                    <div><FileModal filename={sources.Source1.filename} filepath={"https://backend.lumyn.ai/nhpcfiles/"+sources.Source1.filename} pageNumber={sources.Source1.page_number} /></div>
                                  </div>
                                  <div className="source-details">
                                    <p>
                                      {showFullContent ? sources.Source1.page_content : getShortContent(sources.Source1.page_content)}
                                      <a href="#" onClick={toggleContent}>
                                        {showFullContent ? ' View Less' : ' View More'}
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              )}

                              {sources && sources.Source2 && (
                                <div className="source-box">
                                  <div className="source-head">
                                    {/*<div>File 2: {sourceFile2}</div>*/}
                                    {/* <a href="#"> Download <i class="fa-solid fa-download"></i> </a> */}
                                    <div><FileModal filename={sources.Source2.filename} filepath={"https://backend.lumyn.ai/nhpcfiles/"+sources.Source2.filename} pageNumber={sources.Source2.page_number} /></div>
                                  </div>
                                  <div className="source-details">
                                    <p>
                                      {showFullContent ? sources.Source2.page_content : getShortContent(sources.Source2.page_content)}
                                      <a href="#" onClick={toggleContent}>
                                        {showFullContent ? ' View Less' : ' View More'}
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              )}

                              {sources && sources.Source3 && (
                                <div className="source-box">
                                  <div className="source-head">
                                    {/*<div>File 3: {sourceFile3}</div>*/}
                                    {/* <a href="#"> Download <i class="fa-solid fa-download"></i> </a> */}
                                    <div><FileModal filename={sources.Source3.filename} filepath={"https://backend.lumyn.ai/nhpcfiles/"+sources.Source3.filename} pageNumber={sources.Source3.page_number} /></div>
                                  </div>
                                  <div className="source-details">
                                    <p>
                                      {showFullContent ? sources.Source3.page_content : getShortContent(sources.Source3.page_content)}
                                      <a href="#" onClick={toggleContent}>
                                        {showFullContent ? ' View Less' : ' View More'}
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              )}
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default NhpcKnowLedgeSearch;
