import React, { useState, useRef } from "react";
import { Form, Button, Card } from "react-bootstrap";
import SideBarMobile from "../components/side-bar";
import { NavLink, Link } from "react-router-dom";
import HeaderNavbar from "../components/headernavbar";
import HRAIService from "../services/HRAIService";
import { ThreeDots } from 'react-loader-spinner';
import { toast } from "react-toastify";
import SEO from "../components/seo";

const HRSalaryData = () => {
    //defining the state variables
    const [key, setKey] = useState("123456789");
    const [company, setCompany] = useState("Acompworld");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [title, setTitle] = useState("");
    const [details, setDetails] = useState("");
    const [currency, setCurrency] = useState("USD");

    const [responsedata, setResponseData] = useState("");
    const [progress, setProgress] = useState(0);
    const [showprogress, setShowProgress] = useState(false);
    const [showprogressjt, setShowProgressJD] = useState(false);


    //method to handle the form submit
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (title === "") {
            toast.error("Please enter Job Title");
        }
        else if (details === "") {
            toast.error("Please enter Job Description");
        }
        else if (city === "") {
            toast.error("Please enter City");
        }
        else if (state === "") {
            toast.error("Please enter State");
        }
        else if (currency === "") {
            toast.error("Please enter Currency");
        }

        else {
            setShowProgressJD(true)

            const formData = new FormData();
            formData.append('key', "123456789");
            formData.append('job_title', title);
            formData.append('job_description', details);
            formData.append('city', city);
            formData.append('state', state);
            formData.append('currency', currency);
            console.log("Pre formdata", formData);

            //Change button text to "Loading..." while waiting for response

            const submitButton = document.querySelector('button[type="submit"]');
            submitButton.disabled = true;
            submitButton.innerText = "Processing...";

            setShowProgressJD(true);


            //calling the extract method from AIService
            const response = await HRAIService.GetSalaryData(formData);
            console.log(response);

            //Change button text back to "Proceed" after response
            submitButton.disabled = false;
            submitButton.innerText = "Get Details";

            console.log("response ", response.average_salary);

            //setting the data in the state
            setResponseData(response);
            setShowProgressJD(false);
        }
    };

    return (
        <div>
            <SEO
                title='Lumyn - Generative AI Sandbox'
                description='Lumyn - Generative AI Sandbox'
                keywords='' />
            <main>
                <section>
                    <div className="container-fluid p-0">
                        <div className="d-flex mobile-nonedflex">
                            <div className="">
                                <div className="mobile-hidden">
                                    <nav className="left__sidebar">
                                        <div className="brand__image">
                                            <img src="/images/logo-brand.png" alt="Band Logo" className="brand__logo" />
                                        </div>
                                        <div className="left-side-item">
                                            <ul className="nav__bar__link">
                                                <li>
                                                    <NavLink to="/humanresource-job-description">Create Job Description</NavLink>
                                                </li>
                                                <li>
                                                    <a className="active_link">Get Salary</a>                                                    
                                                </li>
                                                <li>
                                                    <NavLink to="/humanresource-resume-analysis">Resume Analysis</NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>

                            <div
                                className=""
                                style={{
                                width: "100%"
                            }}>
                                <div className="right__sidebar">
                                    <HeaderNavbar />
                                    <div className="fix-section">
                                        <div className="row gx-4">
                                            <div className="col-md-5 source-file-bgonly px-4">                                                
                                                <Form onSubmit={handleSubmit}>
                                                    <div className='chat__section '>
                                                        <div className=''>
                                                            <h5 className="text-headingenter pt-3">
                                                                Enter Details to get Salary Details</h5>

                                                            <div className='chat__boxquestion'>
                                                                <div className='paddingpx'>
                                                                    <input
                                                                        type='text'
                                                                        name=''
                                                                        id=''
                                                                        className='tittle__input'
                                                                        placeholder='Job Title'
                                                                        onChange={(e) => setTitle(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className='context-area'>
                                                                <textarea
                                                                    className='context__textarea'
                                                                    placeholder='Job Description' onChange={(e) => setDetails(e.target.value)}></textarea>
                                                            </div>

                                                            <div className='chat__boxquestion'>
                                                                <div className='paddingpx'>
                                                                    <input
                                                                        type='text'
                                                                        name=''
                                                                        id=''
                                                                        className='tittle__input'
                                                                        placeholder='City'
                                                                        onChange={(e) => setCity(e.target.value)}
                                                                    />
                                                                    <input
                                                                        type='text'
                                                                        name=''
                                                                        id=''
                                                                        className='tittle__input'
                                                                        placeholder='State'
                                                                        onChange={(e) => setState(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='width-control'>
                                                            <div className='list-dflex'>
                                                                <div className='tone'>
                                                                    <p className='tone__text'>Currency</p>
                                                                    <ul>
                                                                        <li
                                                                            onClick={(e) => setCurrency("USD")}
                                                                            className={currency === "USD"
                                                                                ? 'tabcolor sub-button'
                                                                                : "sub-button"}>USD</li>
                                                                        <li
                                                                            onClick={(e) => setCurrency("INR")}
                                                                            className={currency === "INR"
                                                                                ? 'tabcolor sub-button'
                                                                                : "sub-button"}>INR</li>                                                                            
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>                                                            

                                                        <div className='px-4 button-genration'>
                                                            <button className='genration-btn' type="submit">Get Details</button>
                                                        </div>
                                                        <div className="blank50">
                                                            &nbsp;
                                                        </div>
                                                    </div>
                                                </Form>                                               
                                            </div>
                                            
                                            {/* Check if response data is not empty and reponsedata.status is success*/}
                                            {responsedata ? (
                                                <div className="col-md-6 px-3">
                                                    <div className="">
                                                        <div className="sourcecard-body">

                                                            <h5 className="text-headingenter pt-3" style={{paddingLeft:"0px"}}>Salary Data</h5>

                                                            <div class="invoice-details mt-4">
                                                                <p>
                                                                    <strong>Average Salary:</strong><br/>
                                                                    {currency} {responsedata.average_salary}<br/>
                                                                </p>
                                                                <br/>
                                                                <p>
                                                                    <strong>Upper Limit:</strong><br/>
                                                                    {currency} {responsedata.upper_limit}<br/>
                                                                </p>
                                                                <br/>
                                                                <p>
                                                                    <strong>Lower Limit:</strong><br/>
                                                                    {currency} {responsedata.lower_limit}<br/>
                                                                </p>
                                                                <br/>
                                                                <p>
                                                                    <strong>References:</strong><br/>
                                                                    <ul>
                                                                        {responsedata.references.map((reference, index) => (
                                                                            <li key={index}>
                                                                                <a href={reference} target="_blank" rel="noopener noreferrer" style={{color:"#ffffff"}}>
                                                                                    {reference}
                                                                                </a>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                <p> No data found </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default HRSalaryData;
