import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom'

const HeaderNavbar = () => {
    const location = useLocation();
    const [section, setSection] = useState('');

    useEffect(() => {
        const path = location.pathname;
        const page = path.split('/')[1];
        const section = page.split('-')[0];   
        
        console.log("Section",section);
        if(section==null || section=="" || section==undefined)
        {
            setSection("knowledge");
        }else{
            setSection(section);
        }
    }, [location]);

    const handleLogout = () => {
        localStorage.clear();
         window.location.href = "/";
      };


    return (
        <div>
            <header className="desktopdisplayblock">
    <nav className="d-flex justify-content-between">
        <ul className="rigth__sidebarul">
            <li>
                <Link to="/knowledge-search" className={section === 'knowledge' ? 'active__headeraher' : ''}>
                    <img src="images/icon-knowledge.svg" className="top__barimg" /> Knowledge Search
                </Link>
            </li>
            <li>
                <Link to="/doc-qa" className={section === 'doc' ? 'active__headeraher' : ''}>
                    <img src="images/icon-knowledge.svg" className="top__barimg" /> DocQA
                </Link>
            </li>
            <li>
                <Link to="/plant-live" className={section === 'plant' ? 'active__headeraher' : ''}>
                    <img src="images/icon-marketing.svg" className="top__barimg" />Plant Live
                </Link>
            </li>
            <li>
                <Link to="/humanresource-job-description" className={section === 'humanresource' ? 'active__headeraher' : ''}>
                    <img src="images/icon-hr.svg" className="top__barimg" /> HR AI
                </Link>
            </li>   
            <li>
                <Link to="/finance-process-invoice" className={section === 'finance' ? 'active__headeraher' : ''}>
                    <img src="images/icon-finance.svg" className="top__barimg" /> Finance AI
                </Link>
            </li> 
        </ul>
        <div>
            <ul className="ul-dleftype">               
                
                <li>
                    <div className="user-icon-dropdown">
                        <img src="images/search-home/user-icon.png" className="avterimg" />                       
                    </div>
                </li>
                <li>
                    <div className="text-white user-icon-dropdown">
                    Hi Admin
                        <div className="dropdown-content">
                           <button className="dropdown-item" onClick={handleLogout}>
                            Logout
                        </button>
                    </div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>



            <header className="mobile-responsivedsplyblock">
                <nav class="navbar navbar-expand-lg">
                    <div class="container-fluid">
                        <a class="navbar-brand" href="#">
                            <img src="/images/logo-brand.png" alt="Band Logo" className="brand__logo" /></a>
                        <button
                            class="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation">
                            <i class="fa-solid fa-bars text-white"></i>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNav">
                            <ul class="navbar-nav">

                                <li class="nav-item">
                                    <Link to="/knowledge"
                                        className={location.pathname === '/knowledge' ? 'active__headeraher' : ''}
                                    >
                                        {" "}
                                        <img src="images/google-search.png" className="top__barimg" />{" "}
                                        DocQA
                                    </Link>
                                </li>
                                <li class="nav-item">
                                    <Link to="/plant-live"
                                        className={location.pathname === '/plant' ? 'active__headeraher' : ''}
                                    >
                                        <img src="images/google-search.png" className="top__barimg" />{" "}
                                        Plant Live
                                    </Link>
                                </li>
                                {/* <li class="nav-item">
                                    <Link to="/marketing"
                                        className={location.pathname === '/marketing' ? 'active__headeraher' : ''}
                                    >
                                        <img src="images/google-search.png" className="top__barimg" />
                                        Marketing{" "}
                                    </Link>
                                </li> */}

                                <li>
                                    <Link to="/"
                                        className={location.pathname === '/' ? 'active__headeraher' : ''}
                                    >
                                        <img src="images/google-search.png" className="top__barimg" />
                                        HR AI
                                    </Link>
                                </li>

                                {/* <li>
                                    <Link to="/personal"
                                        className={location.pathname === '/personal' ? 'active__headeraher' : ''}>
                                        <img src="images/google-search.png" className="top__barimg" />
                                        Personal
                                    </Link>
                                    <li className='mt-4'>
                                        <ul className="ul-dleftype">
                                            <li className="text-white">Hi Lisa!</li>
                                            <li><img src="images/avter.png" className="avterimg" /></li>
                                        </ul>

                                    </li>

                                </li> */}
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </div>
    )
}

export default HeaderNavbar
