import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

function SubcategoryList({ subcategories, setSelectedSubcategory, selectedSubcategory, handleShowUpdate, handleDeleteSubcategory }) {

    return (
      <ul className="subcategory-list">
        {subcategories.map((subcat, index) => (
          <li 
            key={index} 
            onClick={() => setSelectedSubcategory(subcat.name)}
            className={`category-item ${selectedSubcategory === subcat.name ? 'active' : ''}`}
          >
            <span className="category-name">{subcat.name}</span>
            <div className="icon-group">
              <FontAwesomeIcon
                icon={faEdit}
                className="icon"
                onClick={(e) => {
                  e.stopPropagation(); // Prevents triggering the onClick event of the list item
                  handleShowUpdate(subcat.id,subcat.name,subcat.category);
                }}
              />
              <FontAwesomeIcon
                icon={faTrash}
                className="icon"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteSubcategory(subcat.id,subcat.name);
                }}
              />
            </div>
          </li>
        ))}
      </ul>
    );
  }

export default SubcategoryList;
