import React, { useState } from "react";
// import { login as loginAPI } from './authService';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  const loginInitialState = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [login, setLogin] = useState(loginInitialState);
  const [error, setError] = useState(false);

  // onchange function for above state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLogin({
      ...login,
      [name]: value,
    });
  };

  // onsubmit function for above state
  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!login.email || !login.password) {
    //   setError(true);
    //   return;
    // }
    // const reqBody = {
    //   email: login.email,
    //   password: login.password,
    // };

    // try {
    //   const response = await loginRequest(reqBody);

    //   if (response.success === true) {
    //     localStorage.setItem("token", response.data.token);
    //     localStorage.setItem("user", JSON.stringify(response.data.user));
    //     Swal.fire({
    //       icon: "success",
    //       title: "Login Success",
    //       text: "You are successfully logged in",
    //     });
    //     navigate("/proposal");
    //   }
    // } catch (e) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: e.response.data.message,
    //   });
    // }
    if (login.email === "admin@lumyn.ai" && login.password === "admin@123") {
      localStorage.setItem("token", "luymnadmin");
      navigate("/knowledge-search");
    }
    else if (login.email === "admin" && login.password === "admin") {
      localStorage.setItem("token", "luymnadmin");
      navigate("/knowledge-search");
    }
    else if (login.email === "nhpc" && login.password === "nhpc") {
      localStorage.setItem("token", "nhpcadmin");
      navigate("/nhpc-knowledge-search");
    }
    else if (login.email === "ntpc" && login.password === "ntpc") {
      localStorage.setItem("token", "ntpcadmin");
      navigate("/ntpc-doc-extract");
    }
    else {
      toast.error('Please enter valid credentials');
    }

  };

  return (
    <>
      <div class="container-fluid">
        <div className="row">
          <div className="col-12 top-container">
          <a href="https://acompworld.com/" target="_blank"> <img
              src="/images/acompworld-logo.svg"
              alt="Acompworld Logo" width={200}
            /></a> 
          </div>
        </div>

        <div class="row login-box-container" >
          <div class="col-md-6 col-sm-12 ">
            <div className="left-box">
              <img
                src="/images/logo-brand.png"
                alt="logo"
              />
              <h4>Generative AI Sandbox</h4>
            </div>
           
          </div>
          <div class="col-md-6 col-sm-12">

            <div class="card login-box mt10">
              <div class="login-heading">


                <h4 class="">Experience Cutting Edge AI</h4>
              </div>
              <div class="card-body">

                <form>
                  <div class="mb-3">
                    <input
                      type="text"
                      placeholder="Username"
                      name="email"
                      required
                      value={login.email}
                      onChange={(e) => {
                        e.target.value = e.target.value
                          .replace(/[^a-zA-Z0-9@.]/g, "")
                          .trim();
                        handleInputChange(e);
                      }}
                      class="form-control"
                      id="email"
                      aria-describedby="emailHelp"
                    />
                    <small className="error">
                      {error && !login.email ? "Email is required" : ""}
                      {error && login.email && !login.email.includes("@")
                        ? "Invalid email"
                        : ""}
                    </small>
                  </div>
                  <div class="mb-3 position-relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      value={login.password}
                      name="password"
                      onChange={handleInputChange}
                      required
                      placeholder="Password"
                      class="form-control position-relative"
                      id="password"
                    />

                    <div className="password-visibility">
                      {showPassword ? (
                        <i
                          class="fas fa-eye-slash"
                          onClick={togglePasswordVisibility}
                        ></i>
                      ) : (
                        <i
                          class="fas fa-eye"
                          onClick={togglePasswordVisibility}
                        ></i>
                      )}
                    </div>

                  </div>

                  <button
                    type="button"
                    onClick={(e) => handleSubmit(e)}
                    class="btn btn-login"
                  >
                    Login
                  </button>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
