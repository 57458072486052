import React, { useState, useRef, useEffect } from "react";
import { Form, Button, Card, Modal, Row, Col } from "react-bootstrap";
import SideBarMobile from "../components/side-bar";
import { NavLink, Link } from "react-router-dom";
import SideBarMobileAnalyzeDoc from "../components/side-bar-analyzedoc";
import HeaderNavbar from "../components/headernavbar";
import KnowLedgeAIService from "../services/KnowledgeAIService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactMic } from "react-mic";
import { saveAs } from "file-saver";
import lamejs from "lamejs";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import { ThreeDots } from "react-loader-spinner";
import SEO from "../components/seo";
import axios from "axios";
import ProgressButton from "react-progress-button";
const KnowLedge = () => {
  const [key, setKey] = useState("123456789");
  const [question, setQuestion] = useState("");
  const [prevQuestion, setPrevQuestion] = useState("");
  const [prev_answer, setPrevAnswer] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [showprogress, setShowProgress] = useState(false);
  const [showAudioprogress, setAudioShowProgress] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [progress, setProgress] = useState(0);
  const [recordedFile, setRecordedFile] = useState(null);
  const [isRecording, setIsRecording] = useState(false);

  const [showfile, setShowFile] = useState(false);
  const [fileUploadReply, setfileUploadReply] = useState(null);

  const [audioChunks, setAudioChunks] = useState([]);

  const [sources, setSources] = useState(null);
  const [isFileSave, setisFileSave] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);

  const handleShowAddCategoryModal = () => setShowAddCategoryModal(true);
  const handleCloseAddCategoryModal = () => setShowAddCategoryModal(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };
  const [Categorydata, setCategoryData] = useState([]);
  const [categoryNodes, setCategoryNodes] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [category, setCategory] = useState("");
  const [fileName, setfileName] = useState("");
  const [newCategory, setNewCategory] = useState("");

  const [categoryFileList, setCategoryFileList] = useState([]);
  const [categoryFileNodes, setCategoryFileNodes] = useState([]);
  const [CategoryFileData, setCategoryFileData] = useState([]);

  const [sourceFile1, setSourceFile1] = useState('');
  const [sourceFile2, setSourceFile2] = useState('');
  const [sourceFile3, setSourceFile3] = useState('');

  const [showFullContent, setShowFullContent] = useState(false);
  //method to handle the form submit
  const messageEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messageEndRef.current)
      messageEndRef.current.scrollTop = messageEndRef.current.scrollHeight;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!question) {
      toast.error("Please enter a question.");
      return;
    }

    const conversation = {
      prev_question: prevQuestion,
      prev_answer: prev_answer,
      new_question: question,
    };

    //Creating json data
    const data = {
      key: key,
      conversation,
      clientid: "atspl",
      category: category,
      index:"multidoc-test-2"
    };

    console.log("data", data);
    const div = document.createElement("div");

    div.innerHTML =
      `<div class='chat__boxanswer'>
      <div class='hedingcopyoption__flexd' id="queChat">
      <span class=''>
        <img src='images/search-home/user-icon.png' alt='' class='user-icon' />
         </span>
        <div>
          <p class='question'>` +
      question +
      `</p>
          </p>
        </div>
      </div>
    </div>`;

    document.getElementById("chat").appendChild(div);
    setQuestion("");
    setShowProgress(true);
    const response = await KnowLedgeAIService.searchMultiDoc(data);

    let answer = "";
    let tempsources = [];

    console.log("responseeee", response);
    console.log("sources ", response.sources);

    if (response?.sources) {
      setSources(response.sources);

      if (response.sources.Source1) {
        setSourceFile1(response.sources.Source1.doc_title);
      }
      if (response.sources.Source2) {
        setSourceFile2(response.sources.Source2.doc_title);
      }

      if (response.sources.Source3) {
        setSourceFile3(response.sources.Source3.doc_title);
      }

    }
    //check if response contains answer key
    if (response?.answer) {
      answer = response.answer;
    }

    if (!response || !answer) {
      answer =
        "Sorry! But I could not find any relevant information to answer your question.";
    }
    const divContainer = document.createElement("div");

    divContainer.innerHTML =
      `<div class='chat__boxanswer'>
          <div class='hedingcopyoption__flexd' id="queChat">
          <span class=''>
            <img src='images/search-home/flag-icon.png' alt='' class='user-icon' />
             </span>
             <div class='fade-in-element bottom fade-in-bottom'>
              <div class='answer'>` +
              convertTextToHtmlString(answer) +
              `</div>
              </p>
            </div>
          </div>
        </div>`;

    document.getElementById("chat").appendChild(divContainer);
    setPrevQuestion(question);
    setPrevAnswer(answer);

    setQuestion("");

    setShowProgress(false);
  };

  const handleFileSave = async (e) => {
    e.preventDefault();
    if (!fileName) {
      toast.error("Please enter a file name.");
      return;
    } else if (!selectedFile) {
      toast.error("Please select a file to upload.");
      return;
    } else {
      try {
        console.log("selectedFile", selectedFile.type);
        if (selectedFile) {          
          const allowedTypes = [
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            "application/vnd.ms-powerpoint",
            //images
            "image/jpeg",
            "image/png",
            "image/gif",
            //Excel File
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            //Powerpoint
            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",            
          ];
          if (!allowedTypes.includes(selectedFile.type)) {
            // Handle case where file type is not allowed
            toast.error(
              "Invalid file type. Please upload a PDF, DOC, or DOCX file."
            );

            return;
          }
          setisFileSave(true);
          setSelectedFile(selectedFile);
          const formData = new FormData();
          formData.append("file", selectedFile);
          formData.append("clientid", "atspl");
          formData.append("category", category);
          // formData.append("category", "HR");
          formData.append("key", "123456789");
          formData.append("docid", selectedFile.name);
          formData.append("index", "multidoc-test-2");
          formData.append("compress", "true");
          const response = await KnowLedgeAIService.uploadMultilefilewithname(
            formData
          );

          const response1 = await handleAddFileNameInCategory(e);
          uploadFile(selectedFile);
          setfileName("");
          setShowFile(true);
          setisFileSave(false);
          setPrevQuestion("");
          setPrevAnswer("");
          fetchFileNameByCategory(category);

          if (response) {
            /// close modal after file upload
            handleCloseModal();
          }

          //close div with id AddFile
          /*  var modal = document.getElementById('AddFile');
           var modalInstance = bootstrap.Modal.getInstance(modal);
           modalInstance.hide(); */
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        // Handle the error (e.g., display an error message to the user)
      }
    }
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setSelectedFile();
    console.log("selectedFile", file.type);

    const allowedTypes = [
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            "application/vnd.ms-powerpoint",
            //images
            "image/jpeg",
            "image/png",
            "image/gif",
            //Excel File
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            //Powerpoint
            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",            
          ];

    if (!allowedTypes.includes(file.type)) {
      // Handle case where file type is not allowed
      toast.error("Invalid file type. Please upload a PDF, DOC, or DOCX file.");

      return;
    }

    setSelectedFile(file);
  };

  const uploadFile = async (file) => {
    const totalPercentage = 100;
    let startPercent = 0;
    const interval = setInterval(() => {
      // Simulate progress
      startPercent++;
      const newProgress = (startPercent / totalPercentage) * 100;
      setProgress(newProgress);
      if (startPercent === totalPercentage) {
        // File upload is complete
        clearInterval(interval);
        setIsFilePicked(true);
        setfileUploadReply("File has been processed and is ready to use!");
      }
    }, 30);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const addAudioElement = async (audioBlob) => {
    try {
      console.log("Uploading audio...", audioBlob);

      if (!audioBlob) {
        console.error("No audio blob provided");
        return;
      }

      ////const url = URL.createObjectURL(audioBlob.blob);
      // console.log(url,"---");

      //const audioBlob = await fetch(blob).then(r => r.blob());
      //const audiofile = new File([audioBlob], "audiofile", { type: "audio/mp3" });
      const audioFile = new File([audioBlob], "filename.webm", {
        type: "audio/webm",
      });
      const formData = new FormData();
      formData.append("file", audioFile);
      formData.append("key", "123456789");
      setAudioShowProgress(true);

      const response = await KnowLedgeAIService.uploadAudioFile(formData);

      if (response.status === 200) {
        console.log("Audio uploaded successfully", response.data.transcription);
        setfileUploadReply("Audio has been processed and is ready to use!");
        callSearchAPI(response.data.transcription);
      } else {
        //const errorData = await response.json();
        console.error("Failed to upload audio:", response);
        setfileUploadReply("Failed to process audio");
      }
    } catch (error) {
      console.error("Error uploading audio:", error);
      setfileUploadReply("Error in uploading audio");
      setAudioShowProgress(false);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [showprogress, showAudioprogress]);

  const handleInputChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleStartRecording = () => {
    setIsRecording(true);
  };

  const handleStopRecording = () => {
    setIsRecording(false);
  };

  const onStop = (recordedBlob) => {
    setAudioChunks([...audioChunks, recordedBlob.blob]);
    console.log("recordedBlob is: ", recordedBlob);
  };

  const handleDownload = async () => {
    if (audioChunks.length === 0) {
      console.log("No audio recorded.");
      return;
    }

    const Mp3Encoder = lamejs.Mp3Encoder;
    const mp3encoder = new Mp3Encoder(1, 44100, 128); // mono 44.1kHz, 128 kbps

    let mp3Data = [];
    for (const chunk of audioChunks) {
      const buffer = chunk.buffer.slice(0);
      const samples = new Int16Array(buffer);

      const mp3buf = mp3encoder.encodeBuffer(samples);
      if (mp3buf.length > 0) {
        mp3Data.push(mp3buf);
      }
    }

    const finalMp3buf = mp3encoder.flush();
    if (finalMp3buf.length > 0) {
      mp3Data.push(finalMp3buf);
    }

    const blob = new Blob(mp3Data, { type: "audio/mp3" });
    saveAs(blob, "recorded_audio.mp3");
  };
  const callSearchAPI = async (text) => {
    //e.preventDefault();
    const conversation = {
      prev_question: prevQuestion,
      prev_answer: prev_answer,
      new_question: text,
    };

    //Creating json data
    const data = {
      key: "123456789",
      conversation,
      clientid: "atspl",
      category: category,
    };

    const div = document.createElement("div");

    div.innerHTML =
      `<div class='chat__boxanswer'>
      <div class='hedingcopyoption__flexd' id="queChat">
      <span class=''>
        <img src='images/search-home/user-icon.png' alt='' class='user-icon' />
         </span>
        <div>
          <p>` +
      text +
      `</p>
          </p>
        </div>
      </div>
    </div>`;

    document.getElementById("chat").appendChild(div);
    setAudioShowProgress(false);
    setShowProgress(true);
    const response = await KnowLedgeAIService.searchMultiDoc(data);

    let answer = "";

    if (response?.sources) {
      setSources(response.sources);

      if (response.sources.Source1) {
        setSourceFile1(response.sources.Source1.doc_title);
      }
      if (response.sources.Source2) {
        setSourceFile2(response.sources.Source2.doc_title);
      }
      if (response.sources.Source3) {
        setSourceFile3(response.sources.Source3.doc_title);
      }
    }
    //check if response contains answer key
    if (response?.answer) {
      answer = response.answer;
    }

    if (!response || !answer) {
      answer =
        "Sorry! But I could not find any relevant information to answer your question.";
    }
    const divContainer = document.createElement("chat");

    divContainer.innerHTML =
      `<div class='chat__boxanswer'>
          <div class='hedingcopyoption__flexd' id="queChat">
          <span class=''>
            <img src='images/search-home/flag-icon.png' alt='' class='user-icon' />
             </span>
            <div class='fade-in-element bottom fade-in-bottom'>
              <p>` +
              convertTextToHtmlString(answer) +
      `</p>
              </p>
            </div>
          </div>
        </div>`;

    document.getElementById("chat").appendChild(divContainer);

    setPrevQuestion(text);
    setPrevAnswer(answer);

    setQuestion("");
    setShowProgress(false);
  };

  const [recordState, setRecordState] = useState(null);

  const start = () => {
    setRecordState(RecordState.START);
  };

  const stop = () => {
    setRecordState(RecordState.STOP);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const response = await KnowLedgeAIService.GetCategory();
    const categories = Object.values(response.data).map(item => item.category);
    const categoryNodes = Object.keys(response.data);
    setCategoryData(categories);
    setSelectedCatogry(categories[0]);
    setCategory(categories[0]);
    setCategoryNodes(categoryNodes);
    console.log("categoryNodes", categoryNodes);
    console.log("categories", categories);
  };

  useEffect(() => {
    if (category) {
      fetchFileNameByCategory(category);
    }
  }, [category]);

  const fetchFileNameByCategory = async (category) => {
    try {
      const response = await KnowLedgeAIService.GetFileByCategory(category);
      const fileNames = Object.values(response.data).map(item => item.filename);
      const fileNodes = Object.keys(response.data);
      const fileData = Object.values(response.data);
      console.log("fileNames", fileNames);
      console.log("fileNodes", fileNodes);
      console.log("fileData", fileData);
      setCategoryFileList(fileNames);
      setCategoryFileNodes(fileNodes);
      setCategoryFileData(fileData);
    } catch (error) {
      console.error("Error getting file names:", error);
    }
  };


  const handleInputFileName = (e) => {
    setfileName(e.target.value);
  };

  // Function to handle the deletion of an item
  const handleDelete = async (index) => {
    //get the node value from the categoryFileNodes by matching the index
    const node = categoryFileNodes[index];
    console.log("node", node);

    const nodedata = CategoryFileData[index];
    console.log("nodedata", nodedata);

    //call the delete api with the node value
    const response = await KnowLedgeAIService.DeleteFileName(node,nodedata);

    //fill the categoryFileList with the updated list
    fetchFileNameByCategory(category);
  };

  
  // Function to handle the deletion of an item
  const handleDeleteCategory = async () => {   

    const index = Categorydata.indexOf(category);
    //get the node value from the categoryFileNodes by matching the index
    const node = categoryNodes[index];
    console.log("node", node);

    //call the delete api with the node value
    const response = await KnowLedgeAIService.DeleteCategory(node);

    //fill the categoryFileList with the updated list
    fetchCategories(category);
  };


  // Initial selection is the ID of the first option
  const initialSelection = Categorydata?.id;
  const [selectedCatogry, setSelectedCatogry] = useState(initialSelection);
  useEffect(() => {
    setSelectedCatogry(initialSelection);
  }, [initialSelection]);
  // Set up state with the initial selection
  // Handle dropdown change
  const handleCategory = (event) => {
    setCategory(event.target.value);
    fetchFileNameByCategory(event.target.value);
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();
    if (!newCategory) {
      toast.error("Please enter a new category.");
      return;
    } else {
      try {
        const data = {
          category: newCategory,
        };
        const response = await KnowLedgeAIService.AddCategory(data);
        if (response) {
          fetchCategories();
          handleCloseAddCategoryModal();
          setNewCategory("");
        }
      } catch (error) {
        console.error("Error adding category:", error);
      }
    }
  }


  const handleAddFileNameInCategory = async (e) => {
    e.preventDefault();
    try {
      const data = {
        clientid: "atspl",
        category: category,
        docid: selectedFile.name,
        filename: fileName,
      };
      console.log("data", data);
      const response = await KnowLedgeAIService.AddFileNameInCategory(data);

    } catch (error) {

    }
  }

  // Function to truncate the item text if it exceeds 20 characters
  const truncateItem = (text) => {
    return text.length > 20 ? text.substring(0, 20) + '...' : text;
  };

  function replaceNewlinesWithBr(str) {
    const convertToHTML = (text) => {
        // Replace ** ** with <h5> </h5>
        text = text.replace(/\*\*(.*?)\*\*/g, '<h5>$1</h5>');
        // Replace * * with <p> </p>
        text = text.replace(/\*(.*?)\*/g, '<p>$1</p>');
        // Replace newlines with <br />
        text = text.replace(/\n/g, '<br />'); 
        
         // Replace more than one <br> with one <br>
         text = text.replace(/(<br\s*\/?>\s*){2,}/g, '<br />');

        return text;
    };

    return convertToHTML(str); // Return the HTML string
  }

  // Function to convert text to an HTML string
  const convertTextToHtmlString = (text) => {
    const lines = text.split('\n');
    let htmlString = '';
    let currentList = null;

    lines.forEach((line, index) => {
      if (line.trim() === '') {
        // Skip empty lines
        return;
      }

      // Handle headings (bold text)
      if (line.startsWith('**') && line.endsWith('**')) {
        if (currentList) {
          htmlString += currentList.join('') + '</ul>';
          currentList = null;
        }
        htmlString += `<h3>${line.substring(2, line.length - 2)}</h3>`;
      } 
      // Handle list items
      else if (line.startsWith('*')) {
        if (!currentList) {
          currentList = [];
          htmlString += '<ul>';
        }
        currentList.push(`<li>${line.substring(2)}</li>`);
      } 
      // Handle regular paragraphs
      else {
        if (currentList) {
          htmlString += currentList.join('') + '</ul>';
          currentList = null;
        }
        htmlString += `<p>${line}</p>`;
      }
    });

    if (currentList) {
      htmlString += currentList.join('') + '</ul>';
    }

    return htmlString;
  };

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const getShortContent = (content) => {
    if (content.length > 250) {
      return content.substring(0, 250) + '...';
    }
    return content;
  };


  return (
    <div>
      <SEO
        title="Lumyn - Generative AI Sandbox"
        description="Lumyn - Generative AI Sandbox"
        keywords=""
      />

      <main>
        <section>
          <div className="container-fluid p-0">
            <div className="d-flex mobile-nonedflex">
              <div className="">
                <div className="mobile-hidden">
                  <nav className="left__sidebar">
                    <div className="brand__image">
                      <img
                        src="/images/logo-brand.png"
                        alt="Band Logo"
                        className="brand__logo"
                      />
                    </div>

                    <div className="left-side-item">
                      <div>
                        <select
                          value={category}
                          onChange={
                            (e) => handleCategory(e)
                          }
                          className="select-dropdown"
                        >

                          {Categorydata.map((opt) => (
                            <option key={opt} value={opt}>
                              {opt}
                            </option>
                          ))}
                        </select>


                        <i
                          class="fa fa-plus addOptons"
                          onClick={handleShowAddCategoryModal}
                        ></i>
                      </div>

                      {/* Delete category lin. display if categoryFileList has items*/}
                      {categoryFileList.length == 0 && <a class="delete_link" onClick={() => handleDeleteCategory()}><i class="fa fa-trash deleteicon"></i> Delete Category</a>}

                      <div className="ulItem">
                        <ul class="nav__bar__link">
                          {categoryFileList.map((item, index) => (
                            <li key={index}>
                              <a href="#" title={item} className="multidoclink"> <i class="fa fa-file fileicon"></i> {truncateItem(item)}</a>
                              {/* Delete icon with action event */}
                              <i class="fa fa-trash deleteicon" onClick={() => handleDelete(index)}></i>
                            </li>
                          ))}
                        </ul>


                      </div>
                    </div>
                  </nav>
                  <div className="knowledge-add-file">
                    {/* Your plus icon */}
                    <div>
                      {/* <button className='genration-btn' type="button"
                        data-bs-toggle="modal" data-bs-target="#AddFile"
                        onClick={handleOpenModal}
                      >
                        <i class="fa fa-plus" aria-hidden="true"   ></i> Add File</button> */}
                      <Button
                        type="button"
                        className="genration-btn"
                        onClick={handleOpenModal}
                      >
                        <i
                          class="fa fa-plus addOptons" ></i> Add File
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="" style={{ width: "100%" }}>
                <div className="right__sidebar">
                  <HeaderNavbar />

                  <div className="bg-changecontent knowledgesearch">
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-8">
                          <div class="custom-scrollbar" ref={messageEndRef}>
                            <div className="chat__section">
                              {/* {<div id="uploadfile"> </div>} */}
                              {/* {showfile && <div> {fileName} </div>} */}

                              {
                                <div class="chat__boxanswer">
                                  <div class="sub__boxanswer">
                                    <div className="chat__box">
                                      {" "}
                                      {fileUploadReply}{" "}
                                    </div>
                                  </div>
                                </div>
                              }

                              <div id="chat" className="chat__box">
                                {showAudioprogress && (
                                  <div class="chat__boxanswer">
                                    <div
                                      class="hedingcopyoption__flexd"
                                      id="queChat"
                                    >
                                      <span class="">
                                        <img
                                          src="images/search-home/user-icon.png"
                                          alt=""
                                          class="user-icon"
                                        />
                                      </span>
                                      <div class="custom-loader"></div>
                                    </div>
                                  </div>
                                )}
                                {showprogress && (
                                  <div class="chat__boxanswer">
                                    <div
                                      class="hedingcopyoption__flexd"
                                      id="queChat"
                                    >
                                      <span class="">
                                        <img
                                          src="images/search-home/flag-icon.png"
                                          alt=""
                                          class="user-icon"
                                        />
                                      </span>
                                      <div class="custom-loader"></div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div class="row-20">
                            <Form onSubmit={handleSubmit}>
                              <div className="question-write">
                                <div className="col-lg-12 position-relative tooltip-input">
                                  <input
                                    type="text"
                                    class="form__control placeholder-text"
                                    placeholder="Write new question..."
                                    onChange={(e) => handleInputChange(e)}
                                    value={question}
                                  />
                                  {showTooltip && (
                                    <div className="tooltip">
                                      <div className="position-relative seacrh-icontooplist">
                                        <input
                                          type="text"
                                          class="form__controlsearch"
                                          id=""
                                          placeholder=""
                                        />
                                        <div className="icon__search">
                                          <i class="fa-solid fa-magnifying-glass"></i>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <div>{/* Hidden file input */}</div>
                                  <div className="icon__mic">
                                    <div className="icon__mic">
                                      <AudioRecorder
                                        onRecordingComplete={async (blob) => {
                                          addAudioElement(blob);
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="send__icon">
                                    <div className="d-flex with__gap-20px align-items-start">
                                      <div className="circle__static__done">
                                        <button type="submit">
                                          <i class="fa-solid fa-paper-plane"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          </div>
                        </div>
                        <div class="col-lg-4 custom-scrollbar sources">
                          {sources && <h5 className="source-head5">Sources</h5>}
                          {
                            <>
                              {sources && sources.Source1 && (
                                <div className="source-box">
                                  <div className="source-head">
                                    <div>File 1: {sourceFile1}</div>
                                    {/* <a href="#"> Download <i class="fa-solid fa-download"></i> </a> */}
                                  </div>
                                  <div className="source-details">
                                    <p>
                                      {showFullContent ? sources.Source1.page_content : getShortContent(sources.Source1.page_content)}
                                      <a href="#" onClick={toggleContent}>
                                        {showFullContent ? ' View Less' : ' View More'}
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              )}

                              {sources && sources.Source2 && (
                                <div className="source-box">
                                  <div className="source-head">
                                    <div>File 2: {sourceFile2}</div>
                                    {/* <a href="#"> Download <i class="fa-solid fa-download"></i> </a> */}
                                  </div>
                                  <div className="source-details">
                                    <p>
                                      {showFullContent ? sources.Source2.page_content : getShortContent(sources.Source2.page_content)}
                                      <a href="#" onClick={toggleContent}>
                                        {showFullContent ? ' View Less' : ' View More'}
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              )}

                              {sources && sources.Source3 && (
                                <div className="source-box">
                                  <div className="source-head">
                                    <div>File 3: {sourceFile3}</div>
                                    {/* <a href="#"> Download <i class="fa-solid fa-download"></i> </a> */}
                                  </div>
                                  <div className="source-details">
                                    <p>
                                      {showFullContent ? sources.Source3.page_content : getShortContent(sources.Source3.page_content)}
                                      <a href="#" onClick={toggleContent}>
                                        {showFullContent ? ' View Less' : ' View More'}
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              )}
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          show={showModal}
          key={"lgs"}
          onHide={handleCloseModal}
          size="md-sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="categoryModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add File</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row ">
              <div className="col-lg-12">
                <input
                  type="text"
                  class="input-text"
                  placeholder="Enter Title"
                  onChange={(e) => handleInputFileName(e)}
                  value={fileName}
                />
              </div>

              <div className="col-lg-12">
                <input
                  type="file"
                  id="fileInput"
                  className="uploadfile"
                  onChange={(e) => handleFileUpload(e)}
                />
                
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isFileSave ? (
              <span class="loader"></span>
            ) : (
              <button
                onClick={(e) => handleFileSave(e)}
                className="genration-btn btn btn-primary"
              >
                Submit
              </button>
            )}
          </Modal.Footer>
        </Modal>

        <Modal
          show={showAddCategoryModal}
          key={"lgs"}
          onHide={handleCloseAddCategoryModal}
          size="md-sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="categoryModal"
        >
          <Modal.Header closeButton className="">
            <Modal.Title>Add Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row ">
              <div className="col-lg-12">
                <input
                  type="text"
                  class="input-text"
                  placeholder="Enter Category Name"
                  onChange={(e) => setNewCategory(e.target.value)}
                  value={newCategory}

                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isFileSave ? (
              "Uploading..."
            ) : (
              <button
                onClick={(e) => handleAddCategory(e)}
                className="genration-btn btn btn-primary"
              >
                Submit
              </button>
            )}
          </Modal.Footer>
        </Modal>
      </main>


    </div>
  );
};

export default KnowLedge;
