//importing axios library
import { type } from '@testing-library/user-event/dist/type';
import axios from 'axios';

//defining the base url
// const url = 'http://54.156.117.178:4000/';
const baseurl = 'https://backend.lumyn.ai/';


//creating a class for the AI service
class KnowledgeAIService {
    static async search(data) {
        try {
            const response = await axios.post(baseurl + 'get_answer/', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
            console.log("error", error.response);
            console.log("error", error.message);
        }
    }

    static async uploadFile(fileData) {
        const formData = new FormData();
        formData.append('file', fileData);
        formData.append('key', '123456789');
        formData.append('clientid', 'acomp');

        try {
            const response = await axios.post(baseurl + 'vectorize_document/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }



    static async uploadAudioFile(fileData) {

        try {
            const response = await axios.post(baseurl + 'transcribe/', fileData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response);
            return response;
        } catch (error) {
            console.log(error);
        }
    }

    //plant live
    static async plantLive(data) {
        try {
            const response = await axios.post(baseurl + 'plant_live/', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
            console.log("error", error.response);
            console.log("error", error.message);
        }
    }

    static async uploadPlantLiveAudioFile(fileData) {

        try {
            const response = await axios.post(baseurl + 'transcribe/', fileData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response);
            return response;
        } catch (error) {
            console.log(error);
        }
    }
    //get all file

    static async uploadMultilefilewithname(formData) {

        try {
            const response = await axios.post(baseurl + 'multidoc_add_doc/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    static async searchMultiDoc(data) {
        try {
            const response = await axios.post(baseurl + 'multidoc_get_answer/', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
            console.log("error", error.response);
            console.log("error", error.message);
        }
    }

    //get gateway
    static async GetCategory() {

        try {
            const response = await axios.get('https://lumyn-backend-default-rtdb.firebaseio.com/category.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk&=123456789', {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(response);
            return response;
        } catch (error) {
            console.log(error);
        }
    }
    static async AddCategory(data) {
        try {
            const response = await axios.post('https://lumyn-backend-default-rtdb.firebaseio.com/category.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk&=123456789', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
            console.log("error", error.response);
            console.log("error", error.message);
        }
    }

    static async GetFileByCategory(category) {

        try {
            const response = await axios.get(`https://lumyn-backend-default-rtdb.firebaseio.com/filenames.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk&orderBy="category"&equalTo="${category}"`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(response);
            return response;
        } catch (error) {
            console.log(error);
        }
    }

    static async AddFileNameInCategory(data) {
        try {
            const response = await axios.post('https://lumyn-backend-default-rtdb.firebaseio.com/filenames.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
            console.log("error", error.response);
            console.log("error", error.message);
        }
    }

    //delete file name
    static async DeleteFileName(node, nodedata) {
        try {
            const response = await axios.delete(`https://lumyn-backend-default-rtdb.firebaseio.com/filenames/${node}.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            //call the lumyn api to delete the file
            try {
                const formData = new FormData();
                formData.append('key', '123456789');
                formData.append('clientid', nodedata.clientid);
                formData.append('category', nodedata.category);
                formData.append('type', 'document');
                formData.append('doc_id', nodedata.docid);


                const response1 = await axios.post(baseurl + 'multidoc_delete/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                console.log(response1);
            } catch (error) {
                console.log("error", error);
                console.log("error", error.response);
                console.log("error", error.message);
            }

            return response.data;
        } catch (error) {
            console.log("error", error);
            console.log("error", error.response);
            console.log("error", error.message);
        }
    }

    //delete category
    static async DeleteCategory(node) {
        try {
            const response = await axios.delete(`https://lumyn-backend-default-rtdb.firebaseio.com/category/${node}.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
            console.log("error", error.response);
            console.log("error", error.message);
        }
    }


    //NHPC Services
    static async List_NHPCCategory() {
        try {
            const response = await axios.get('https://lumyn-backend-default-rtdb.firebaseio.com/nhpc-category.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk', {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(response);
            return response;
        } catch (error) {
            console.log(error);
        }
    }
    static async AddCategory(data) {
        try {
            const response = await axios.post('https://lumyn-backend-default-rtdb.firebaseio.com/category.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk&=123456789', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
            console.log("error", error.response);
            console.log("error", error.message);
        }
    }
}


//exporting the class
export default KnowledgeAIService;
