import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import KnowLedgeAIService from "../services/service"; // Import the API service

function SubcategoryModal({ show, handleClose, action, refreshSubcategories, modalData }) {
  const [subcategoryName, setSubcategoryName] = useState("");

  useEffect(() => {
    console.log("modalData",modalData);
    if (modalData && modalData.subcategoryName) {
      setSubcategoryName(modalData.subcategoryName); // Set the existing name for editing
    } else {
      setSubcategoryName(""); // Reset for adding a new category
    }
  }, [modalData]);

  const handleSave = async () => {
    if (modalData.selectedCategory.trim() === "") {
      toast.error("Category name cannot be empty.");
      return;
    }

    if (subcategoryName.trim() === "") {
      toast.error("Subcategory name cannot be empty.");
      return;
    }

    const newSubCategory = { category: modalData.selectedCategory, subcategory: subcategoryName };

    try {
      if (modalData && modalData.subcategoryId) {
        // If `modalData.categoryId` exists, update the category
        await KnowLedgeAIService.Update_NHPCSubCategory(modalData.subcategoryId, newSubCategory);
        toast.success("Subcategory updated successfully");
      } else {
        // Otherwise, add a new category
        await KnowLedgeAIService.Add_NHPCSubCategory(newSubCategory);
        toast.success("Subcategory added successfully");
      }

      refreshSubcategories(); // Refresh the category list
      handleClose(); // Close the modal on successful save
    } catch (error) {
      console.error("Failed to save category:", error);
      toast.error("Failed to save category. Please try again.");
    }


    setSubcategoryName('');
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{action} Subcategory</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Subcategory Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter subcategory name"
            value={subcategoryName}
            onChange={(e) => setSubcategoryName(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SubcategoryModal;
