//importing axios library
import { type } from '@testing-library/user-event/dist/type';
import axios from 'axios';

//defining the base url
// const url = 'http://54.156.117.178:4000/';
const baseurl = 'https://backend.lumyn.ai/';


//creating a class for the AI service
class service {    
    //NHPC Services
    //Category
    static async List_NHPCCategory() {
        try {
            const response = await axios.get('https://lumyn-backend-default-rtdb.firebaseio.com/nhpc-category.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk', {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            //console.log(response);
            return response;
        } catch (error) {
            console.log(error);
        }
    }
    static async Add_NHPCCategory(data) {
        try {
            const response = await axios.post('https://lumyn-backend-default-rtdb.firebaseio.com/nhpc-category.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk&=123456789', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
            console.log("error", error.response);
            console.log("error", error.message);
        }
    }
    static async Update_NHPCCategory(categoryId, updatedCategory) {
        try {
          const response = await axios.put(
            `https://lumyn-backend-default-rtdb.firebaseio.com/nhpc-category/${categoryId}.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk`,
            updatedCategory,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
          return response.data; // Return the updated category data
        } catch (error) {
          console.error("Error updating category:", error);
          throw error; // Rethrow the error for handling in the calling function
        }
    }
    static async Delete_NHPCCategory(categoryId) {
        try {
          const response = await axios.delete(
            `https://lumyn-backend-default-rtdb.firebaseio.com/nhpc-category/${categoryId}.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk`,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
          return response.data; // Return the updated category data
        } catch (error) {
          console.error("Error deleting category:", error);
          throw error; // Rethrow the error for handling in the calling function
        }
    }

    //SubCategory
    static async List_NHPCSubCategory(category) {
        try {
            const response = await axios.get(`https://lumyn-backend-default-rtdb.firebaseio.com/nhpc-subcategory.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk&orderBy="category"&equalTo="${category}"`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            //console.log(response);
            return response;
        } catch (error) {
            console.log(error);
        }
    }

    static async Add_NHPCSubCategory(data) {
        try {
            const response = await axios.post('https://lumyn-backend-default-rtdb.firebaseio.com/nhpc-subcategory.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk&=123456789', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
            console.log("error", error.response);
            console.log("error", error.message);
        }
    }

    static async Update_NHPCSubCategory(subcategoryId, updatedCategory) {
        try {
          const response = await axios.put(
            `https://lumyn-backend-default-rtdb.firebaseio.com/nhpc-subcategory/${subcategoryId}.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk`,
            updatedCategory,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
          return response.data; // Return the updated category data
        } catch (error) {
          console.error("Error updating subcategory:", error);
          throw error; // Rethrow the error for handling in the calling function
        }
    }

    static async Delete_NHPCSubCategory(subcategoryId) {
        try {
          const response = await axios.delete(
            `https://lumyn-backend-default-rtdb.firebaseio.com/nhpc-subcategory/${subcategoryId}.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk`,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
          return response.data; // Return the updated category data
        } catch (error) {
          console.error("Error deleting subcategory:", error);
          throw error; // Rethrow the error for handling in the calling function
        }
    }   
    

    //Documents
    
    static async List_NHPCDocuments(category, subcategory) {
        try {
            const response = await axios.get(`https://lumyn-backend-default-rtdb.firebaseio.com/nhpc-documents.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk&orderBy="category"&equalTo="${category}"`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            // Filter the results further by subcategory on the client side
            const documents = response.data;
            const filteredDocuments = Object.keys(documents).reduce((acc, key) => {
                const document = documents[key];
                if (document.subcategory === subcategory) {
                    acc[key] = document;
                }
                return acc;
            }, {});
    
            //console.log("filteredDocuments", filteredDocuments); // Correct logging of the filtered documents
            return filteredDocuments; // Return filteredDocuments directly, without .Object
        } catch (error) {
            console.log("Error fetching documents:", error);
            return {}; // Return an empty object in case of an error
        }
    }    
    static async Add_NHPCDocument(data) {
        try {
            const response = await axios.post('https://lumyn-backend-default-rtdb.firebaseio.com/nhpc-documents.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
            console.log("error", error.response);
            console.log("error", error.message);
        }
    }    
    static async AddFileNameInCategory(data) {
        try {
            const response = await axios.post('https://lumyn-backend-default-rtdb.firebaseio.com/filenames.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
            console.log("error", error.response);
            console.log("error", error.message);
        }
    }    
    static async GetFileByCategory(category) {

        try {
            const response = await axios.get(`https://lumyn-backend-default-rtdb.firebaseio.com/filenames.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk&orderBy="category"&equalTo="${category}"`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(response);
            return response;
        } catch (error) {
            console.log(error);
        }
    }
    static async DeleteFileName(node, nodedata) {
        try {
            const response = await axios.delete(`https://lumyn-backend-default-rtdb.firebaseio.com/filenames/${node}.json?auth=4tvpFW4m2vGuxA9Hfq5AbvQdWR8jfDRu4s5zVpkk`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            //call the lumyn api to delete the file
            try {
                const formData = new FormData();
                formData.append('key', '123456789');
                formData.append('clientid', nodedata.clientid);
                formData.append('category', nodedata.category);
                formData.append('type', 'document');
                formData.append('doc_id', nodedata.docid);


                const response1 = await axios.post(baseurl + 'multidoc_delete/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                console.log(response1);
            } catch (error) {
                console.log("error", error);
                console.log("error", error.response);
                console.log("error", error.message);
            }

            return response.data;
        } catch (error) {
            console.log("error", error);
            console.log("error", error.response);
            console.log("error", error.message);
        }
    }


    //Other Functions
    static async uploadFileinVectorDB(formData) {

        try {
            const response = await axios.post(baseurl + 'multidoc_process_doc/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    static async uploadMultilefilewithname(formData) {

        try {
            const response = await axios.post(baseurl + 'multidoc_add_doc/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    static async uploadAudioFile(fileData) {

        try {
            const response = await axios.post(baseurl + 'transcribe/', fileData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response);
            return response;
        } catch (error) {
            console.log(error);
        }
    }

    static async searchMultiDoc(data) {
        try {
            const response = await axios.post(baseurl + 'multidoc_process_answer/', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
            console.log("error", error.response);
            console.log("error", error.message);
        }
    }
}


//exporting the class
export default service;
