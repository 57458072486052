//importing axios library
import axios from 'axios';

//defining the base url
const url = 'https://workpodapi.lumyn.ai/';

//creating a class for the AI service
class ResumeAIService {
    
    //method to call the post api with the form data. Form data will have key, type, and image file
    static async clean_resume(formData) {
        try {
            const response = await axios.post(url + 'clean_resume', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    static async shortlist(formData) {
        try {
            const response = await axios.post(url + 'shortlist', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
}

//exporting the class
export default ResumeAIService;
