import React, { useState, useRef } from "react";
import { Form, Button, Card } from "react-bootstrap";
import SideBarMobile from "../components/side-bar";
import { NavLink, Link } from "react-router-dom";
import HeaderNavbar from "../components/headernavbar";
import HRAIService from "../services/HRAIService";
import SEO from "../components/seo";

const HumanResourceResumeAnalysis = () => {    
    const [isDragging, setIsDragging] = useState(false);
    //defining the state variables
    const [file, setFile] = useState("");
    const [key, setKey] = useState("123456789");
    const [jobDescription, setJobDescription] = useState("");
    const [responsedata, setResponseData] = useState("");
    const [progress, setProgress] = useState(0);
    const [isFilePicked, setIsFilePicked] = useState(false);

    //method to handle the form submit
    const handleSubmit = async (e) => {
        
        e.preventDefault();
        //creating the form data
        const formData = new FormData();
        formData.append("resume_file", file);
        formData.append("key", key);
        formData.append("jd_text", jobDescription);
        console.log(file);

        //Change button text to "Loading..." while waiting for response
        const submitButton = document.querySelector('button[type="submit"]');
        submitButton.disabled = true;
        submitButton.innerText = "Processing...";

       //calling the extract method from AIService
       const response = await HRAIService.ResumeAnalysis(formData);
       console.log("response----",response);

        //Change button text back to "Proceed" after response
        submitButton.disabled = false;
        submitButton.innerText = "Proceed";

        //setting the data in the state
        setResponseData(response);
    };

    //creating function to replace newlines with <br> in the report
    // function replaceNewlinesWithBr(str) {
    //     return str.split("\n").map((item, key) => {
    //         return (
    //             <span key={key}>
    //                 {item}
    //                 <br />
    //             </span>
    //         );
    //     });
    // }    

    function replaceNewlinesWithBr(str) {
        const convertToHTML = (text) => {
            // Replace ### with <h3> without a trailing <br />
            text = text.replace(/^### (.*$)/gim, '<h3>$1</h3>');
            // Replace newlines with <br />, except if they follow a heading
            text = text.replace(/(?<!<\/h3>)\n/g, '<br />');
            return text;
        };
    
        return (
            <div
                dangerouslySetInnerHTML={{ __html: convertToHTML(str) }}
            />
        );
    }
    

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        //const files = e.dataTransfer.files;
        // Handle the dropped files (e.g., upload or process them)
        const file = e.dataTransfer.files[0];
        setFile(file);
        console.log('Dropped files:', e.dataTransfer.files[0]);
        if (file) {
            // Start the file upload process
            uploadFile(file);
        }        
    };

    //method to handle the file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFile(file);
        console.log('selected files:', e.target.files[0]);      
        if (file) {
            // Start the file upload process
            uploadFile(file);            
        }
    };

    const uploadFile = (file) => {
        const totalPercentage = 100;
        let startPercent = 0;
    
        const interval = setInterval(() => {
          // Simulate progress
          startPercent++;
          const newProgress = (startPercent / totalPercentage) * 100;
          setProgress(newProgress);
    
          if (startPercent === totalPercentage) {
            // File upload is complete
            clearInterval(interval);
            setIsFilePicked(true);
          }
        }, 30);
    }

    return (
        <div>
            <SEO
                title='Lumyn - Generative AI Sandbox'
                description='Lumyn - Generative AI Sandbox'
                keywords='' />

            <main>
                <section>
                    <div className="container-fluid p-0">
                        <div className="d-flex mobile-nonedflex">
                            <div className="">
                                <div className="mobile-hidden">
                                    <nav className="left__sidebar">
                                        <div className="brand__image">
                                            <img src="/images/logo-brand.png" alt="Band Logo" className="brand__logo" />
                                        </div>
                                        <div className="left-side-item">
                                            <ul className="nav__bar__link">
                                                <li>
                                                    <NavLink to="/humanresource-job-description">Create Job Description</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/humanresource-salary-data">Get Salary</NavLink>
                                                </li>
                                                <li>
                                                    <a className="active_link">Resume Analysis</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>

                            <div
                                className=""
                                style={{
                                width: "100%"
                            }}>
                                <div className="right__sidebar">
                                    <HeaderNavbar/>
                                    <div className="fix-section">
                                        <div className="row gx-4">
                                            <div className="col-md-5 source-file-bgonly px-4">
                                                <Form onSubmit={handleSubmit}>
                                                    <Form.Group controlId="formText" className="mb-3">
                                                        <h5 className="text-headingenter my-4">Job Description</h5>
                                                        {/*Multiline textbox */}
                                                        <div className='context-area'>
                                                            <textarea
                                                                    rows={10}
                                                                    className='context__textarea'
                                                                    placeholder='Job Description' onChange={(e) => setJobDescription(e.target.value)}>                                                                
                                                            </textarea>
                                                        </div>
                                                    </Form.Group>

                                                    <h5 className="text-headingenter my-4">
                                                        Upload File</h5>
                                                    
                                                    <div className='context-area'>
                                                        <div
                                                            className={`file-upload ${isDragging
                                                            ? 'dragging'
                                                            : ''}`}
                                                            onDragEnter={handleDragEnter}
                                                            onDragOver={(e) => e.preventDefault()}
                                                            onDragLeave={handleDragLeave}
                                                            onDrop={handleDrop}>

                                                            <p className="position-relative">Drag and Drop files or folders, or 
                                                            <input type="file" placeholder="Browse" className="file-input" onChange={handleFileChange}/>
                                                                <span className="or-browse">
                                                                    Browse</span>
                                                            </p>
                                                            <span className="sublinetag">maximum file size 20 mb</span>                                                        
                                                        </div>

                                                        <div className="alert alert-progressbar m-3" style={{ display: file ? 'block' : 'none' }}>
                                                            <div className="d-flex align-items-center gx-4">
                                                            <div className="progress-barimages">
                                                                <img src="images/file-image.svg" alt="" className="" />
                                                            </div>
                                                            <div className="progressbar-word">
                                                                <div className="task-progress">
                                                                <p>{`Uploading file ${progress.toFixed(0)}%`}</p>
                                                                <progress className="progress progress2" max="100" value={progress}></progress>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>

                                                        { isFilePicked ? (
                                                        <div className="mt-2 pt-lg-5"style={{textAlign: "right"}}>
                                                            <Button className="btn process-btn" type="submit">
                                                            Proceed
                                                            </Button>                                                        
                                                        </div>):null}
                                                    </div>
                                                </Form>
                                            </div>


                                            {/* Check if response data is not empty and reponsedata.status is success*/}
                                            {responsedata ? (

                                            <div className="col-md-7 px-3">
                                                <div className="">
                                                    <div className="sourcecard-body resumeanalysis">
                                                        <div class="resumeanalysis-details mt-2">                                                            
                                                            <h2>Detailed Report:</h2> {replaceNewlinesWithBr(responsedata.ResumeAnalysis)}                                  
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            ) : (
                                                <div>
                                                    <p> </p>
                                                </div>
                                            )}
                                        </div>
                                    </div> 
                                </div> 
                            </div>
                        </div> 
                    </div> 
                </section>
            </main>
        </div>
    );
};

export default HumanResourceResumeAnalysis;
