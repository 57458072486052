import React, { useState } from "react";
import SideBarMobile from "../components/side-bar";
import { NavLink, Link } from "react-router-dom";
import SideBarMobileAnalyzeDoc from "../components/side-bar-analyzedoc";
import HeaderNavbar from "../components/headernavbar";

const Analytics = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [status, setStatus] = useState("Resume");
  const handleInputClick = () => {
    setShowTooltip(true);
  };
  return (
    <div>
      <main>
        <section>
          <div className="container-fluid p-0">
            <div className="d-flex mobile-nonedflex">
              <div className="">
                <div className="mobile-hidden">
                  <nav className="left__sidebar">
                    <div className="brand__image">
                      <img
                        src="/images/logo-brand.png"
                        alt="Band Logo"
                        className="brand__logo"
                      />
                    </div>
                    <ul className="nav__bar__link">
                      <li>
                        <Link
                          href="#"
                          onClick={(e) => setStatus("Resume")}
                          className={status === "Resume" ? "active_link" : ""}
                        >
                          Resume Analysis
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="#"
                          onClick={(e) => setStatus("JobDescription")}
                          className={
                            status === "JobDescription" ? "active_link" : ""
                          }
                        >
                          Job Description
                        </Link>
                      </li>
                      <li>
                        <Link href="#">Employee Engagement</Link>
                      </li>
                      <li>
                        <Link href="#">Employee Performance</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>

              <div className="">
                <div className="right__sidebar">
                  <HeaderNavbar />

                  <nav>
                    <ul className="nav__bar__link d-nonedesktop">
                      <li>
                        <Link
                          href="#"
                          onClick={(e) => setStatus("Resume")}
                          className={status === "Resume" ? "active_link" : ""}
                        >
                          Resume Analysis
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="#"
                          onClick={(e) => setStatus("JobDescription")}
                          className={
                            status === "JobDescription" ? "active_link" : ""
                          }
                        >
                          Job Description
                        </Link>
                      </li>
                      <li>
                        <Link href="#">Employee Engagement</Link>
                      </li>
                      <li>
                        <Link href="#">Employee Performance</Link>
                      </li>
                    </ul>
                  </nav>
                  <div className="bg-changecontent">
                    <div className="chat__section">
                      <div className="chat__boxquestion mb-5">
                        <img
                          className="avter__icon"
                          src="images/search-home/avter-icon.png"
                          alt="avter"
                        />
                        <div>
                          <div class="input-group">
                            <span class="input-group-text bg-doc">
                              <img
                                src="images/analyzedoc/pdf-document-img.png"
                                className="docimg"
                                alt=""
                              />
                            </span>
                            <input
                              type="text"
                              class="form-control bg-traprent"
                              aria-label=""
                              value="DesignOne.pdf"
                            />
                            <span class="input-group-text bg-traprent">
                              | <i class="fa-solid fa-trash-can"></i>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="chat__boxanswer">
                        <div className="sub__boxanswer">
                          <div className="hedingcopyoption__flexd">
                            <span className="sub__question__text">
                              <img
                                src="images/search-home/flag-icon.png"
                                alt=""
                                className=""
                              />{" "}
                              Of course! I'm here to help.
                            </span>
                            <div className="icon__copyedit">
                              <Link>
                                {" "}
                                <img
                                  src="images/search-home/copy-icon.svg"
                                  alt=""
                                  className="copy__icon"
                                />
                              </Link>
                            </div>
                          </div>
                          <p>
                            The sky appears blue because of a phenomenon called
                            scattering. Tiny particles and molecules in the
                            atmosphere, like nitrogen and oxygen, scatter
                            sunlight in all directions. Blue light is scattered
                            more than other colors because it travels in
                            shorter, smaller waves. This is why we see the sky
                            as blue most of the time.
                          </p>

                          <button className="regenerate">
                            <img
                              src="images/search-home/reset-icon.svg"
                              className="reset-icon"
                              alt=""
                            />{" "}
                            Regenerate
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="question-write">
                      <div className="col-lg-7 position-relative tooltip-input">
                        <input
                          type="text"
                          class="form__control"
                          id=""
                          placeholder="Write new question..."
                          onClick={handleInputClick}
                          style={{
                            padding: "0.986rem 4rem",
                          }}
                        />{" "}
                        {showTooltip && (
                          <div className="tooltip">
                            <div className="position-relative seacrh-icontooplist">
                              <input
                                type="text"
                                class="form__controlsearch"
                                id=""
                                placeholder=""
                              />
                              <div className="icon__search">
                                <i class="fa-solid fa-magnifying-glass"></i>
                              </div>
                            </div>
                            <ul>
                              <li>
                                <a href="#">Science</a>
                              </li>
                              <li>
                                <a href="#">Management</a>
                              </li>
                              <li>
                                <a href="#">Technology</a>
                              </li>
                              <li>
                                <a href="#">Design</a>
                              </li>
                              <li>
                                <a href="#">Agriculture</a>
                              </li>
                            </ul>
                          </div>
                        )}
                        <div className="plusicon">
                          <i class="fa-solid fa-plus"></i>
                        </div>
                        <div className="send__icon">
                          <span class="material-symbols-outlined">send</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Analytics;
