import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";


function CategoryList({ categories, setSelectedCategory, setSelectedSubcategory, selectedCategory, handleShowUpdate, handleDeleteCategory }) {
  const [loading, setLoading] = useState(false); // Loading state

  const handleEditCategory = async (id, name) => {
    setLoading(true); // Start loading
    await handleShowUpdate(id, name); // Call the edit function
    setLoading(false); // Stop loading
  };

  const handleRemoveCategory = async (id, name) => {
    setLoading(true); // Start loading
    await handleDeleteCategory(id, name); // Call the delete function
    setLoading(false); // Stop loading
  };

  return (
    <div>
      {loading && (
        <div className="loading-indicator">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}

      <ul className="category-list">
        {categories.map((cat, index) => (
          <li
            key={index}
            onClick={() => {
              setSelectedCategory(cat.name);
              setSelectedSubcategory(null);
            }}
            className={`category-item ${selectedCategory === cat.name ? 'active' : ''}`}
          >
            <span className="category-name">{cat.name}</span>
            <div className="icon-group">
              <FontAwesomeIcon
                icon={faEdit}
                className="icon"
                onClick={(e) => {
                  e.stopPropagation(); // Prevents triggering the onClick event of the list item
                  handleEditCategory(cat.id, cat.name);
                }}
              />
              <FontAwesomeIcon
                icon={faTrash}
                className="icon"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveCategory(cat.id, cat.name);
                }}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CategoryList;
