import React from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';

const ExampleComponent = () => {
  const { startRecording, stopRecording, mediaBlobUrl } = useAudioRecorder({
    onRecordingComplete: (blob) => addAudioElement(blob),
  });

  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    document.getElementById("audiofile").appendChild(audio);
  };

  return (
    <div>
      {/* Control buttons (optional) */}
      <button onClick={startRecording}>Start Recording</button>
      <button onClick={stopRecording}>Stop Recording</button>
      
      {/* Audio Recorder component */}
      <AudioRecorder />

      {/* Container for the recorded audio */}
      <div id="audiofile"></div>
    </div>
  );
};

export default ExampleComponent;
